import axios from 'axios';
// import config from "../lib/config"
import {MediaUploadResponse, ViaAcademyPostCreate} from "../features/media/mediaSlice";
import {CoachFeedbackUpdateRequest, CoachFeedbackUploadClip} from "../features/reviewtool/reviewtoolSlice";
import {isIntroSegment, isNormalSegment, isSummarySegment} from "../lib/utils";

import {AppConfig} from "../config";
import {FeedTableRequestParams} from "../features/access-zone/accessZoneSlice";
import {getUserSession} from "./aws/Cognito";


// const api_host = "https://qa-vfa.api.kaizhan.io"
const api_host = AppConfig.API_BASE_URL

export interface S3urlsRequest {
    filename: string
    idToken: string
}

export const api = axios.create({
    baseURL: api_host,
    headers: {
        Accept: "application/json",
    },
});

export function apiUpdateIdToken(token: string) {
    api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}


// api.interceptors.request.use(
//     (config) => {
//         const token = getIdToken();
//         if (config.headers) {
//             config.headers["Authorization"] = 'Bearer ' + token;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

api.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const userSession = await getUserSession();
                    if(userSession){
                        const idToken = userSession.getIdToken().getJwtToken()
                        apiUpdateIdToken(idToken)
                        sessionStorage.setItem("idToken", idToken)
                    }else{
                        window.location.assign("/logout")
                    }
                } catch (_error) {
                    window.location.assign("/logout")
                }
            }

            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);



export async function getAccessZoneReviewRequests(args: FeedTableRequestParams) {
    const statuses: string = args.statuses.length ? "&status=" + args.statuses.join(",")  : ""
    const response = await api.get(
        '/v1/coach-feedback-requests?page=' + args.pageParams.page + '&pageSize=' +
        args.pageParams.pageSize + statuses + '&ordering=created_at&time=' +(new Date()),
    );
    console.log(response)
    return response.data
}

export async function getAccessZoneTargetReviewRequest(id: string) {
    const response = await api.get(
        '/v1/coach-feedback-requests/' + id + '?time=' +(new Date())
    );
    console.log(response)
    return response.data
}

export async function getProfile(id: string) {
    const response = await api.get(
        '/v1/profiles/' + id
    );
    console.log(response)
    return response.data
}


export async function getS3urls(args: S3urlsRequest) {
    const response = await api.get(
        '/v1/media-upload-url/' + args.filename,
    );
    console.log(response)
    return response.data
}

export const getMediaUploadResponse = async (args: S3urlsRequest): Promise<MediaUploadResponse> => {
    return await getS3urls(args)
}

export async function getViaAcademyPosts() {
    const response = await api.get(
        '/v1/via-academy/posts/?page=1&pageSize=100',
    );
    console.log(response)
    return response.data.results
}

export async function getViaUserProfile() {
    const response = await api.get(
        '/v1/profile',

    );
    console.log(response)
    return response.data
}

export async function createViaAcademyPost(args: ViaAcademyPostCreate) {
    const response = await api.post(
        '/v1/via-academy/posts/',
        args,
    );

    console.log(response)
    return response.data
}

export async function putCoachFeedbackStatusVideos(args: CoachFeedbackUpdateRequest) {
    const videos = args.payload.videos
    let send_videos: CoachFeedbackUploadClip[]|undefined
    if(videos && videos.length){
        send_videos = videos.filter(v => isIntroSegment(v.label))
        send_videos = send_videos.concat(videos.filter(v=> isNormalSegment(v.label)))
        send_videos = send_videos.concat(videos.filter(v=> isSummarySegment(v.label)))
    }else {
        send_videos = []
    }
    console.log("videos",videos)
    console.log("send_videos",send_videos)
    const detail = args.payload.detail !== undefined ? {detail: args.payload.detail} : {}
    const response =
        args.payload.status !== undefined
            ? await api.put(
                '/v1/coach-feedback-requests/' + args.uuid + '/feedback/status',
                {status: args.payload.status, ...detail},
            )
            : await api.put(
                '/v1/coach-feedback-requests/' + args.uuid + '/feedback/media',
                {videos: send_videos},
            )

    console.log(response)
    return response.data
}

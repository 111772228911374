import React, {useCallback, useEffect, useState} from 'react'
import {Button, CircularProgress, TextField, Typography} from "@mui/material";

import {CognitoUserGroup, signIn, getUserSession, isUserInGroup} from "../../services/aws/Cognito";
import {apiUpdateIdToken} from "../../services/api";
import {getUserProfileThunk, updateIsAdmin, updateIsCoach, updateIsModerator} from "./userSlice";
import {useAppDispatch} from "../../redux/hooks";
import {CognitoUser} from "amazon-cognito-identity-js";


export function LoginForm() {

    const dispatch = useAppDispatch()
    const [userEmail, setUserEmail] = useState<string>("")
    const [userPassword, setUserPassword] = useState<string>("")
    const [formErrorText, setFormErrorText] = useState<string>("")
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false)

    const refreshSession = useCallback(
        async () => {
            const userSession = await getUserSession();
            const idToken = userSession?.getIdToken().getJwtToken()
            if(idToken){
                sessionStorage.setItem("idToken", idToken)
                apiUpdateIdToken(idToken)
                dispatch(getUserProfileThunk())
            }

            const userIsCoach = await isUserInGroup(CognitoUserGroup.Coaches);
            dispatch(updateIsCoach(userIsCoach))
            const userIsModerator = await isUserInGroup(CognitoUserGroup.Moderators);
            dispatch(updateIsModerator(userIsModerator))
            const userIsAdmin = await isUserInGroup(CognitoUserGroup.Admin);
            dispatch(updateIsAdmin(userIsAdmin))
            console.log('Is User an Admin user?', userIsAdmin);
            console.log('Is User a Moderator?', userIsModerator);
            console.log('Is User a Coach?', userIsCoach);
        }, [dispatch])

    useEffect(()=> {
        const runInitUser = async () => {
            await refreshSession()
        }
        runInitUser().then()

    },[dispatch, refreshSession])

    const authenticateUser = async () => {
        setIsAuthenticating(true)
        const signInResponse: string | CognitoUser = await signIn({username: userEmail, password: userPassword});
        // const user = await getLoggedInUser();
        setIsAuthenticating(false)
        if (typeof signInResponse !== 'string') {
            await refreshSession()
        } else {
            if(signInResponse.indexOf("UserNotFoundException") !== -1
                || signInResponse.indexOf("NotAuthorizedException") !== -1
                || userEmail === ""
                || userPassword === "" ){
                setFormErrorText("The user email or password is incorrect")
            }
            else
                setFormErrorText(signInResponse)
            console.log('Invalid credentials!');
        }
    }

    return (
        <div>
        {
            sessionStorage.getItem("idToken") ?
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
                        <CircularProgress/>
                </div>
                :
                <div className="login-page">
                    <div style={{minHeight: "205px"}}>
                        <img className="login-page-logo" src={process.env.PUBLIC_URL + "/login_logo.svg"}
                             alt="Via Academy Logo"/>
                    </div>
                    <div>
                        <span className="login-page-title">VIA Studio</span>
                    </div>
                    <div className="login-form-fields">
                        <TextField
                            error={formErrorText !== ""}
                            className="login-page-email"
                            color="primary"
                            label={
                                <Typography
                                    className="login-page-text-placeholder"
                                >
                                    Email
                                </Typography>
                            }
                            variant="outlined"
                            onChange={e => {
                                setFormErrorText("")
                                setUserEmail(e.currentTarget.value)
                            }}
                        />
                    </div>
                    <div className="login-form-fields">
                        <TextField
                            error={formErrorText !== ""}
                            className="login-page-password"
                            color="primary"
                            label={
                                <Typography
                                    className="login-page-text-placeholder"
                                >
                                    Password
                                </Typography>
                            }
                            type="password"
                            variant="outlined"
                            onChange={e => {
                                setFormErrorText("")
                                setUserPassword(e.currentTarget.value)
                            }}
                            helperText={formErrorText}
                        />
                    </div>
                    <div className="login-form-fields">
                        {isAuthenticating ?
                            <CircularProgress color="inherit"/>
                            :
                            <Button className="login-page-submit" size="large" type="submit" onClick={authenticateUser}
                                    variant="outlined">Login </Button>
                        }
                    </div>
                    {/*<footer className="login-page-footer">*/}
                    {/*    <p><code>¯\_(ツ)_/¯</code></p>*/}
                    {/*</footer>*/}
                </div>
        }
        </div>
    )
}





import React, {useEffect} from 'react'
import {Checkbox, ListItemText, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getViaAcademyPostsThunk, ViaAcademyPost} from "../features/media/mediaSlice";
import {RootState} from "../redux/store";


// enum visibilityType {
//     Hidden = "Hidden",
//     Public = "Public"
// }
//
//
// interface ContentVideo {
//     title: string
//     thumbnail_url: string
//     description: string
//     isPro: boolean
//     academy: string
//     visibility: visibilityType
//     publish_date: string
//     publish_status: string
//     views: number
// }
//
// const videos: ContentVideo[] = [
//     {
//         title: "video1 is here",
//         thumbnail_url: "https://placekitten.com/480/340",
//         description: "this move is super awesome",
//         isPro: true,
//         academy: "QPR",
//         visibility: visibilityType.Public,
//         publish_date: "November 11, 2022",
//         publish_status: "Published",
//         views: 84
//     },
//     {
//         title: "video2 is here",
//         thumbnail_url: "https://placekitten.com/480/340",
//         description: "this move is also super awesome",
//         isPro: false,
//         academy: "QPR",
//         visibility: visibilityType.Hidden,
//         publish_date: "November 12, 2022",
//         publish_status: "Published",
//         views: 284
//     }
// ]
//
// const academyIconUrl = (academy: string) => {
//     if(academy === "QPR"){
//         return process.env.PUBLIC_URL + "/club_logos/qpr_logo.png"
//     } else if (academy === "Fulham FC") {
//         return process.env.PUBLIC_URL + "/club_logos/ffc_logo.png"
//     } else if (academy === "Peterborough United FC") {
//         return process.env.PUBLIC_URL + "/club_logos/pb_logo.png"
//     } else
//         return process.env.PUBLIC_URL + "/login_logo.svg"
// }

// const academyIcon = (academy: string) => {
//         return <img src={academyIconUrl(academy)} style={{height:"48px"}} alt={academy}/>
// }

export default function ContentFeed() {

    const dispatch = useAppDispatch()
    const items = useAppSelector<ViaAcademyPost[]>((state: RootState) => state.media.contentPosts)

    useEffect(() => {
        dispatch(getViaAcademyPostsThunk())
    },[dispatch])

    return(
        <div>
            <h1>Content</h1>
            <Table style={{width: "100%"}}>
                <TableHead>
                    <TableRow>
                        <TableCell className="video-cell"><Checkbox/></TableCell>
                        <TableCell className="video-cell">Video</TableCell>
                        <TableCell>Description</TableCell>
                        {/*<TableCell>V1 Review Tool</TableCell>*/}
                        <TableCell>Review Tool</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                { items.map( (v,i) =>

                    <TableRow key={i} className="content-video-feed-row">
                        <TableCell>
                            <Checkbox/>
                        </TableCell>
                        <TableCell>
                            {v.media === null ?
                                <span>Media Not Available</span>
                                :
                                <img className="content-feed-thumbnail" src={v.media.video.frame_image.url}
                                     alt={v.title}/>
                            }
                        </TableCell>
                        <TableCell sx={{verticalAlign: "top"}}>
                            <ListItemText
                                sx={{minWidth: "300px"}}
                                primary={v.title}
                                secondary={v.uuid + "\n  " + v.media.video.key + ""}
                            />
                        </TableCell>
                        {/*<TableCell>*/}
                        {/*    {v.media === null ?*/}
                        {/*        <Button variant="contained" disabled={true}>Review</Button>*/}
                        {/*        :*/}
                        {/*        <Button variant="contained" href={"/review.html?video_url=" + v.media.video.url + "&org_logo=" + academyIconUrl("") } target="_blank">Review</Button>*/}
                        {/*    }*/}
                        {/*</TableCell>*/}
                        <TableCell>
                            {/*{v.media === null ?*/}
                            {/*    <Button variant="contained" disabled={true}>Review</Button>*/}
                            {/*    :*/}
                            {/*    <Button variant="contained" onClick={() => {*/}
                            {/*        dispatch(newV2Review({uri: v.media.video.url, type: VideoType.submission} as VideoUpdate))*/}
                            {/*        dispatch(updateCurrentMode("ReviewTool"))*/}
                            {/*    }}>Review</Button>*/}
                            {/*}*/}
                        </TableCell>
                    </TableRow>
                )}
                </TableBody>
            </Table>



        </div>
    )
}
import React from 'react'
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
    addRecordedSegment,
    cancelRecordingInProgress,
    CoachFeedbackUpdatePayload,
    CoachFeedbackUpdateRequest,
    RecordedSegment, removeRecordedSegment,
    updateCoachFeedbackThunk,
    updateMainPaneVideo,
    updateRecordedSegmentUri,
    updateRecordingInProgress,
    VideoType, VideoURI
} from "./reviewtoolSlice";
import {
    getLastRecordingLength,
    getRecordedSegmentUrl,
    resetRecordedChunks,
    setAudioRecordStartTime,
    setRecordClip, setRecordingEndTime,
    setRecordingStartTime,
    stopRecordingClip
} from "../../lib/recordingCanvas";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import IconButton from "@mui/material/IconButton";
import StopIcon from '@mui/icons-material/Stop';
import {RootState} from "../../redux/store";
import {getMediaUploadResponse, S3urlsRequest} from "../../services/api";
import {MediaUploadResponse} from "../media/mediaSlice";
import {putVideo} from "../../lib/dataUploads";
import {getSegmentFromRegistry} from "../../lib/recordedSegmentRegistry";
import {ReviewRequestObject} from "../access-zone/accessZoneSlice";
import {addCoachFeedBackClipToVideos, isNonStandardSegment} from "../../lib/utils";


export interface RecordButtonProps {
    currentInProgressClip: string
}


export function RecordButton(props: RecordButtonProps) {

    const dispatch = useAppDispatch()
    const submissionVideo = useAppSelector<VideoURI>((state: RootState) => state.reviewTool.submissionVideo)
    const mainPaneVideoType = useAppSelector<VideoType>((state: RootState) => state.reviewTool.mainPaneVideoType)
    const reviewRequest = useAppSelector<ReviewRequestObject|undefined>((state: RootState) => state.reviewTool.reviewRequest)
    const savedSegments = useAppSelector<RecordedSegment[]>((state: RootState) => state.reviewTool.savedSegments)
    const recordedSegments = useAppSelector<RecordedSegment[]>((state: RootState) => state.reviewTool.recordedSegments)
    // const recordedIntro = useAppSelector<RecordedSegment>((state: RootState) => state.reviewTool.recordedIntro)
    // const recordedSummary = useAppSelector<RecordedSegment>((state: RootState) => state.reviewTool.recordedSummary)

    // polling to update the reviewRequest (feedback.media). disabled while debugging recording problem.
    // useEffect(() => {
    //     if(!!recordedSegments.length && reviewRequest && reviewRequest){
    //         setTimeout(() => {
    //             dispatch(getAccessZoneTargetReviewRequestThunk(reviewRequest.uuid))
    //         }, 5000)
    //     }
    // },[recordedSegments, dispatch, reviewRequest])

    // useEffect(() => {
    //     if(!!recordedSegments.length && reviewRequest && reviewRequest){
    //         setTimeout(() => {
    //             dispatch(getAccessZoneTargetReviewRequestThunk(reviewRequest.uuid))
    //         }, 5000)
    //     }
    // },[recordedIntro])
    //
    // useEffect(() => {
    //     if(!!recordedSegments.length && reviewRequest && reviewRequest){
    //         setTimeout(() => {
    //             dispatch(getAccessZoneTargetReviewRequestThunk(reviewRequest.uuid))
    //         }, 5000)
    //     }
    // },[recordedSummary])

    // const disableRecordButton = props.currentInProgressClip !== 0 && props.currentInProgressClip !== props.currentInProgressClip
    //     && !(props.currentInProgressClip === 200 && props.currentInProgressClip === 1)
    const disableRecordButton =
        mainPaneVideoType !== VideoType.submission &&
        mainPaneVideoType !== VideoType.intro &&
        mainPaneVideoType !== VideoType.summary

    // const pollForSaved = () => {
    //     setTimeout(() => {
    //         const savedSegments = savedSegmentsRef.current
    //         const recordedSegments = recordedSegmentsRef.current
    //         if(reviewRequest)
    //             getAccessZoneTargetReviewRequestThunk(reviewRequest.uuid)
    //         const savedLabels = savedSegments.map(s=> s.label)
    //         console.log(savedLabels)
    //         console.log(savedSegments)
    //         console.log(recordedSegments)
    //         console.log(props.currentInProgressClip)
    //         console.log(savedLabels.indexOf(props.currentInProgressClip))
    //         if(savedLabels.indexOf(props.currentInProgressClip) === -1 && !!recordedSegments.length)
    //             pollForSaved()
    //     }, 2000)
    // }

    return (
    <span className="record-button-component">
        { props.currentInProgressClip === "" ?
            <IconButton
                onClick={() => {
                    resetRecordedChunks()
                    const pnow = performance.now()
                    console.log("updating delay time. setAudio start time: ", pnow)
                    setAudioRecordStartTime(pnow)
                    const now = setRecordingStartTime()
                    const segmentId =
                        mainPaneVideoType === VideoType.intro ? "Intro-" + now :
                            mainPaneVideoType === VideoType.summary ? "Summary-" + now :
                                "Standard-" + now
                    console.log("segmentId: ", segmentId)
                    dispatch(updateRecordingInProgress(segmentId))
                    setRecordClip(segmentId)
                    dispatch(addRecordedSegment({
                        label: segmentId,
                        uri: "",
                        uploadStatus: ""
                    } as RecordedSegment))
                }}
                disabled={disableRecordButton}
                disableRipple={true}
            >
                {/*<FiberManualRecordIcon style={{color: disableRecordButton ? 'grey' : 'red'}}/>*/}
                <div style={{ display: "inline-block", position: 'relative', marginTop: '0.45em'}}>
                    <CircleIcon style={{ fontSize: '1.8em', color: `${disableRecordButton ? 'grey' : 'red'}`, position: 'absolute', top: '-0.5em', left: 0, zIndex: 1 }}/>
                    <CircleOutlinedIcon style={{ fontSize: '1.8em', position: 'absolute', top: '-0.5em', left: 0, zIndex: 2 ,color: 'white' }}/>
                </div>
            </IconButton>
            :
            <IconButton
                onClick={() => {
                    // dispatch(setClipHasRecording(props.currentInProgressClip))
                    stopRecordingClip()
                    setRecordingEndTime()
                    // delay to allow recorded to stop and include final recordedChunks
                    setTimeout(() => {
                        dispatch(cancelRecordingInProgress())
                        const uri = getRecordedSegmentUrl()
                        if(getLastRecordingLength() > 5){
                            console.log("segmentId: ", props.currentInProgressClip)
                            dispatch(updateRecordedSegmentUri({
                                label: props.currentInProgressClip,
                                uri: uri,
                                uploadStatus: "Saving"
                            }))
                            getMediaUploadResponse({filename: "az_" + props.currentInProgressClip + ".mp4" } as S3urlsRequest)
                                .then((uploadURLS: MediaUploadResponse) => {
                                    const result = getSegmentFromRegistry(uri)
                                    if(result.file) {
                                        putVideo(uploadURLS, result.file, () => {
                                            dispatch(updateRecordedSegmentUri({
                                                label: props.currentInProgressClip,
                                                uri: uri,
                                                uploadStatus: "Processing",
                                                key: uploadURLS.key
                                            }))
                                            if(reviewRequest){
                                                const payload : CoachFeedbackUpdatePayload = {
                                                    videos: addCoachFeedBackClipToVideos(props.currentInProgressClip, uploadURLS, savedSegments, recordedSegments),
                                                }
                                                const req = {
                                                    uuid: reviewRequest.uuid,
                                                    payload: payload
                                                } as CoachFeedbackUpdateRequest
                                                dispatch(updateCoachFeedbackThunk(req))
                                            }
                                            // pollForSaved()
                                        })
                                    }
                                })

                            if(isNonStandardSegment(props.currentInProgressClip)){
                                dispatch(updateMainPaneVideo({label: "", uri: submissionVideo, type: VideoType.submission}))
                            }
                        }else{
                            const cancelledClip = {
                                label: props.currentInProgressClip,
                                uri: uri,
                                uploadStatus: "Deleting"
                            } as RecordedSegment
                            dispatch(updateRecordedSegmentUri(cancelledClip))
                            alert("Recording Cancelled. Clip must be longer than 5 seconds")
                            dispatch(removeRecordedSegment(cancelledClip))
                        }

                    }, 100)
                }}
                disabled={disableRecordButton}
                disableRipple={true}
            >
                <div style={{ display: "inline-block", position: 'relative', marginTop: '0.45em' }}>
                    <StopIcon style={{ fontSize: '1.0em', color: 'red', position: 'absolute', top: '-0.5em', left: '0.415em', zIndex: 1 }}/>
                    <CircleOutlinedIcon style={{ fontSize: '1.8em', position: 'absolute', top: '-0.5em', left: 0, zIndex: 2 ,color: 'white' }}/>
                    <span className="recording-text" style={{position: "absolute", color: "red", left: "2em", top: "-0.5em", fontWeight: "bold"}}>RECORDING...</span>
                </div>
            </IconButton>
        }
    </span>
    )
}
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getViaUserProfile} from "../../services/api";

export interface UserProfileOrgProfile {
    uuid: string
    avatar: string
    handle: string
    nationality: UserNationality
    pitch_post_count: number
    following_count: number
    // is_following: null?
    // is_follower: null
    followers_count: number
    // is_blocked: null
}

export interface OrgTheme {
    brand_color: string
}

export interface UserProfileUser {
    uuid: string
    created_at: number
    first_name: string
    last_name: string
    organisation_id? : string
}

export interface UserProfileOrganisation {
    uuid: string
    name: string
    category: number
    about: string
    profile: UserProfileOrgProfile
    abbreviation: string
    theme: OrgTheme

}

export interface UserProfileStub {
    uuid: string
    handle: string
    avatar: string
}

export interface UserNationality {
    code: string
    name: string
    flag: string
}

export interface ViaUserProfile {
    uuid: string
    user: UserProfileUser
    handle: string
    date_of_birth: number
    avatar: string
    nationality: UserNationality
    team_type: string
    playing_position: string
    organisation?: UserProfileOrganisation
    pitch_post_count: number
    following_count: number
    // is_following: null   ??
    // is_follower: null    ??
    followers_count: number
    organisation_role: string
    // is_blocked: null    ??
    // allowed_to_follow: null
    // date_of_graduation: null
    // followed_by_academies: boolean
    // private_followers: boolean
    // follow_requests: boolean
    // own_follow_request_status: null
    // follow_requests_status: null
}


interface userState {
    profile?: ViaUserProfile
    isCoach: boolean
    isModerator: boolean
    isAdmin: boolean
}

// Define the initial state using that type
const initialState: userState = {
    profile: undefined,
    isCoach: false,
    isModerator: false,
    isAdmin: false
}

export const getUserProfileThunk = createAsyncThunk<ViaUserProfile, void>(
    'media/getViaUserProfileThunk',
    async (args: void, thunkAPI) => {
        console.log(thunkAPI)
        const gotten = await getViaUserProfile();
        console.log(gotten);
        return gotten
    }
)



export const userSlice = createSlice({
    name: 'user',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        clearProfile: (state: userState) => {
            state.profile = undefined
        },
        updateProfile: (state: userState, action: PayloadAction<ViaUserProfile>) => {
        state.profile = action.payload
        },
        updateIsCoach: (state, action: PayloadAction<boolean>) => {
            state.isCoach = action.payload
        },
        updateIsModerator: (state, action: PayloadAction<boolean>) => {
            state.isModerator = action.payload
        },
        updateIsAdmin: (state, action: PayloadAction<boolean>) => {
            state.isAdmin = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserProfileThunk.fulfilled, (state: userState, action: PayloadAction<ViaUserProfile>) => {
            state.profile = action.payload
        })
    },
});


export const {clearProfile, updateIsCoach, updateIsModerator, updateIsAdmin, updateProfile} = userSlice.actions;
export default userSlice.reducer

export interface RecordedSegmentRegistryItem{
    objectUrl: string
    file: File
}

export interface SegmentRegistryResult {
    success: Boolean
    quantity: number
    file?: File
    msg?: string
}

let segmentFileRegistry: RecordedSegmentRegistryItem[] = []

export const addSegmentToRegistry = (objectUrl: string, blobs: Blob[], fileProps: FilePropertyBag): SegmentRegistryResult => {
    console.log(fileProps)
    const file = new File(blobs, Date.now()  + '.mp4', {type: "video/mp4"})
    segmentFileRegistry.push({objectUrl, file} as RecordedSegmentRegistryItem)
    return {
        success: true,
        quantity: 1,
        msg: "Added segment"
    }
}

export const getSegmentFromRegistry = (url: string): SegmentRegistryResult => {
    const existing = segmentFileRegistry.filter(s => s.objectUrl === url )
    const existingLength = existing.length
    if(existingLength){
        return {
            success: true,
            quantity: existingLength,
            file: existing[0].file,
            msg: "returned 1/" + existingLength + " found",
        } as SegmentRegistryResult
    }else{
        return {
            success: false,
            quantity: 0,
            msg: "file not found",
        } as SegmentRegistryResult
    }
}

export const clearSegmentRegistry = (): SegmentRegistryResult => {
    const length = segmentFileRegistry.length
    if(length){
        segmentFileRegistry.forEach(s => {
            URL.revokeObjectURL(s.objectUrl)
        })
        segmentFileRegistry = []
        return {
            success: true,
            quantity: length,
            msg: "cleared " + length + " items from registry"
        } as SegmentRegistryResult
    }else
        return {
            success: true,
            quantity: 0,
            msg: "no items in registry"
        } as SegmentRegistryResult
}

export const removeSegmentFromRegistry = (url: string): SegmentRegistryResult => {
    const initial_length = segmentFileRegistry.length
    segmentFileRegistry = segmentFileRegistry.filter(s => s.objectUrl !== url)

    const quantity = initial_length - segmentFileRegistry.length

    URL.revokeObjectURL(url)

    return {
        success: true,
        quantity: quantity,
        msg: "removed " + quantity + " entries"
    } as SegmentRegistryResult
}

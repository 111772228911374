import React from 'react'
import {AppBar, Button, Toolbar, Typography} from "@mui/material";
// import {useAppDispatch} from "../redux/hooks";
// import {userLogout} from "../features/user/userSlice";
import {useNavigate} from "react-router-dom";
// import { styled, alpha } from '@mui/material/styles';
// import InputBase from '@mui/material/InputBase';
// import SearchIcon from '@mui/icons-material/Search';
// import VideoUpload from "../features/video-management/VideoUpload";





// const Search = styled('div')(({ theme }) => ({
//     position: 'relative',
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),
//     '&:hover': {
//         backgroundColor: alpha(theme.palette.common.white, 0.25),
//     },
//     marginRight: theme.spacing(2),
//     marginLeft: 0,
//     width: '100%',
//     [theme.breakpoints.up('sm')]: {
//         marginLeft: theme.spacing(3),
//         width: 'auto',
//     },
// }));
//
// const SearchIconWrapper = styled('div')(({ theme }) => ({
//     padding: theme.spacing(0, 2),
//     height: '100%',
//     position: 'absolute',
//     pointerEvents: 'none',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
// }));
//
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//     color: 'inherit',
//     '& .MuiInputBase-input': {
//         padding: theme.spacing(1, 1, 1, 0),
//         // vertical padding + font size from searchIcon
//         paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//         transition: theme.transitions.create('width'),
//         width: '100%',
//         [theme.breakpoints.up('md')]: {
//             width: '20ch',
//         },
//     },
// }));

export function TopHeader() {

    // const dispatch = useAppDispatch()
    const navigate = useNavigate()

    return (
        <AppBar id="top-header" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <Typography onClick={() => navigate("/")} variant="h5" className="header-title" noWrap component="div">
                    <img className="header-logo" src={process.env.PUBLIC_URL + "/header_logo.svg"} alt="Via Academy Logo"/>
                    VIA STUDIO
                </Typography>
                {/*<Search>*/}
                {/*    <SearchIconWrapper>*/}
                {/*        <SearchIcon />*/}
                {/*    </SearchIconWrapper>*/}
                {/*    <StyledInputBase*/}
                {/*        placeholder="Search…"*/}
                {/*        inputProps={{ 'aria-label': 'search' }}*/}
                {/*    />*/}
                {/*</Search>*/}
                {/*<VideoUpload/>*/}
                <Button style={{marginLeft: "5em"}} onClick={() => {navigate('/logout')} }>Logout</Button>
            </Toolbar>
        </AppBar>
    )
}
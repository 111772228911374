// Copyright 2023 Kaizhan Ltd.

import { Amplify, Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { FederatedSignInOptions } from '@aws-amplify/auth/lib-esm/types';

import {AppConfig} from '../../config';


const configure = () => {
    Amplify.configure({
        Auth: {
            region: AppConfig.COGNITO.REGION,
            userPoolId: AppConfig.COGNITO.USER_POOL_ID,
            userPoolWebClientId: AppConfig.COGNITO.CLIENT_ID,
            authenticationFlowType: 'USER_SRP_AUTH',
            oauth: {
              domain: AppConfig.COGNITO.OAUTH_CUSTOM_DOMAIN,
              scope: [
                'email',
                'profile',
                'openid',
                'aws.cognito.signin.user.admin',
              ],
              redirectSignIn: AppConfig.COGNITO.OAUTH_LOGIN_REDIRECT,
              responseType: 'code'
            },
        },
    });
};

enum CognitoUserGroup {
    Admin = 'Admin',
    Coaches = 'Coaches',
    Moderators = 'Moderators',
}

type SignInParameters = {
    username: string;
    password: string;
  };

async function signIn({ username, password }: SignInParameters) {
    // TODO: Should throw custom errors here
    try {
        const user = await Auth.signIn(username, password);
        const userIsCoach = await isUserInGroup(CognitoUserGroup.Coaches);
        const userIsModerator = await isUserInGroup(CognitoUserGroup.Moderators);
        const userIsAdmin = await isUserInGroup(CognitoUserGroup.Admin);
        if(!(userIsCoach || userIsModerator || userIsAdmin)){
            await signOut()
           return "This user is not authorized"
        }else {
            return user
        }
    } catch (error) {
        console.log('error signing in', error);
      return "" + error
    }
}

async function federatedSignIn(provider: FederatedSignInOptions) {
  try {
    return await Auth.federatedSignIn(provider);
  } catch (error) {
    console.log('error signing in', error);
    return null;
  }
}

async function appleSignIn() {
  return await federatedSignIn({provider: CognitoHostedUIIdentityProvider.Apple});
}

async function facebookSignIn() {
  return await federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook});
}

async function googleSignIn() {
  return await federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
}

async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
}

async function getLoggedInUser() {
    try {
      return await Auth.currentAuthenticatedUser();
    } catch (error) {
      return null;
    }
}

async function getUserSession() {
    const loggedInUser = await getLoggedInUser();
    if (loggedInUser) {
      return await Auth.currentSession();
    }
    return null;
}


async function isUserInGroup(group: CognitoUserGroup) {
  const userSession = await getUserSession();
  if (userSession) {
    const jwt = userSession.getIdToken().decodePayload();
    const groups = jwt['cognito:groups'] || [];
    for (let i = 0; i < groups.length; i++) {
      if (groups[i] === group) {
        return true;
      }
    }
  }
  return false;
}


export {
    configure,
    CognitoUserGroup,
    getLoggedInUser,
    getUserSession,
    isUserInGroup,
    signIn,
    signOut,
    appleSignIn,
    facebookSignIn,
    googleSignIn
}

import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {DrawingTool, setIsIntro} from "../../lib/recordingCanvas";
import {ReviewRequestObject} from "../access-zone/accessZoneSlice";
import {ViaUserProfile} from "../user/userSlice";
import {getAccessZoneTargetReviewRequest, getProfile, putCoachFeedbackStatusVideos} from "../../services/api";
import {
    getDurationFromLabel,
    isIntroSegment, isNormalSegment,
    isSummarySegment,
    removeRecordedThatAreSaved
} from "../../lib/utils";

export type ReviewToolViewMode = "ReviewTool" | "FeedbackReview" | "SubmissionReview"

export const emptyVideoURI: VideoURI = {mp4Src: "", hlsSrc: ""}

export interface reviewPoint {
    creationDate: number
    submissionURI: string
    start: number
    end: number
    tip?: string
    hasRecording: boolean
}

export interface RecordedSegment {
    label: string
    uri: string
    uploadStatus: string
    key?: string
    duration?: number
}

export enum VideoType {
    submission = "SUBMISSION",
    recordedClip = "RECORDED_CLIP",
    intro = "INTRO",
    recordedIntro = "RECORDED_INTRO",
    summary = "SUMMARY",
    recordedSummary = "RECORDED_SUMMARY",
    fullReview = "FULL_REVIEW"
}

export interface VideoUpdate {
    label: string
    uri: VideoURI
    type: VideoType
    seekTo?: number
    playList?: VideoUpdate[]
    duration?: number
}

export interface VideoURI{
    mp4Src: string
    hlsSrc: string
}


// Define a type for the slice state
interface reviewtoolState {
    status: string
    sample_clips: object
    mainPaneVideoURI: VideoURI
    reviewPoints: reviewPoint[]
    recordedIntro: RecordedSegment
    savedSegments: RecordedSegment[]
    recordedSegments: RecordedSegment[]
    recordedSummary: RecordedSegment
    mainPaneVideoType: VideoType
    mainPaneVideoDuration: number | undefined
    submissionVideo: VideoURI
    submissionVideoSpot: number
    currentReviewPoint?: reviewPoint
    reviewVideoURL : VideoURI
    currentlyRecordingClip: string
    drawingToolSelected: DrawingTool
    playList: VideoUpdate[]
    paused: boolean
    lastReviewRequestId: string
    reviewRequest?: ReviewRequestObject
    player?: ViaUserProfile
    showSubmissionDetails: boolean
}

// Define the initial state using that type
const initialState: reviewtoolState = {
    status: "",
    sample_clips:  [
        // {
        //     title: "Cryuff Turn",
        //     url: "https://filedn.com/llrFX3ekiVmhbwhyxoYjTxk/coach_clips/cruyff_turn%20(1080p).mp4"
        // },
        // {
        //     title: "Inside Hook",
        //     url: "https://filedn.com/llrFX3ekiVmhbwhyxoYjTxk/coach_clips/inside_hook%20(1080p).mp4"
        // },
        // {
        //     title: "Outside Hook",
        //     url: "https://filedn.com/llrFX3ekiVmhbwhyxoYjTxk/coach_clips/outside_hook%20(1080p).mp4"
        // }
    ],
    mainPaneVideoURI: emptyVideoURI,
    mainPaneVideoDuration: 0,
    reviewPoints : [],
    recordedIntro: {
        label: "Intro",
        uri: "",
        uploadStatus: ""
    } as RecordedSegment,
    savedSegments : [],
    recordedSegments : [],
    recordedSummary: {
        label: "Summary",
        uri: "",
        uploadStatus: ""
    } as RecordedSegment,
    mainPaneVideoType: VideoType.submission,
    submissionVideo: emptyVideoURI,
    submissionVideoSpot: 0,
    reviewVideoURL: emptyVideoURI,
    currentlyRecordingClip: "",
    drawingToolSelected: "NONE",
    playList: [],
    paused: true,
    lastReviewRequestId: "",
    reviewRequest: undefined,
    player: undefined,
    showSubmissionDetails: true
}

export const getAccessZoneTargetReviewRequestThunk = createAsyncThunk<ReviewRequestObject, string >(
    'reviewtool/getAccessZoneTargetReviewRequestThunk',
    async (args, thunkAPI) => {
        console.log(thunkAPI)
        const gotten = await getAccessZoneTargetReviewRequest(args);
        console.log(gotten);
        return gotten
    }
)

export const getPlayerProfileThunk = createAsyncThunk<ViaUserProfile, string >(
    'reviewTool/getAccessZoneTargetReviewRequestThunk',
    async (args, thunkAPI) => {
        console.log(thunkAPI)
        const gotten = await getProfile(args);
        console.log(gotten);
        return gotten
    }
)

export interface CoachFeedbackUploadClip {
    key: string
    mime_type: string
    label: string
}

export interface CoachFeedbackUpdatePayload {
    status?: string
    videos?: CoachFeedbackUploadClip[]
    detail?: string
}

export interface CoachFeedbackUpdateRequest {
    uuid: string
    payload: CoachFeedbackUpdatePayload
    window_redirect_to?: string
}

export const updateCoachFeedbackThunk = createAsyncThunk<ReviewRequestObject, CoachFeedbackUpdateRequest>(
    'reviewTool/updateCoachFeedbackThunk',
    async (args, thunkAPI) => {
        console.log(thunkAPI)

        const gotten = await putCoachFeedbackStatusVideos(args);
        if(args.window_redirect_to){
            window.location.assign(args.window_redirect_to)
        }
        return gotten
    }
)

const feedbackResponseReducer = (state: reviewtoolState, action: PayloadAction<ReviewRequestObject>) => {
    console.log("TargetReviewRequest", action.payload)
    if (action.payload.feedback) {
        const savedClips: RecordedSegment[] = action.payload.feedback.media.filter(v => !!v.video.url).map(v => {
            console.log(v.video)
            console.log(v.video.url)
            return {
                label: v.video.label,
                uri: v.video.url,
                key: v.video.key,
                uploadStatus: "Saved",
                duration: getDurationFromLabel(v.video.label)
            } as RecordedSegment
        })
        const introClips = savedClips.filter(v => isIntroSegment(v.label))
        if (introClips.length) {
            state.recordedIntro = introClips[0]
            console.log(state.recordedIntro)
        }

        const summaryClips = savedClips.filter(v => isSummarySegment(v.label))
        if (summaryClips.length) {
            state.recordedSummary = summaryClips[0]
            console.log(state.recordedSummary)
        }
        console.log("INTRO CLIP", state.recordedIntro)
        console.log("SAVED CLIPS:", savedClips)
        console.log("SUMMARY CLIP", state.recordedSummary)
        state.recordedSegments = removeRecordedThatAreSaved(savedClips, state.recordedSegments)
        state.savedSegments = savedClips
    }
    state.reviewRequest = action.payload
}

export const reviewtoolSlice = createSlice({
    name: 'reviewTool',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // appendDataPoint: (state, action: PayloadAction<DataPoint>) => {
        //     state.reviewtool_address = action.payload
        //     state.dataPoints.push(action.payload)
        // },
        toggleShowSubmissionDetails: (state) => {
            state.showSubmissionDetails = !state.showSubmissionDetails
        },
        updateSavedSegments: (state, action:PayloadAction<RecordedSegment[]>) => {
            state.savedSegments = action.payload
        },
        setMainPaneVideoDuration: (state, action:PayloadAction<number|undefined>) => {
            state.mainPaneVideoDuration = action.payload
        },
        updatePaused: (state, action:PayloadAction<boolean>) => {
           state.paused = action.payload
        },
        updateDrawingTool: (state, action:PayloadAction<DrawingTool>) => {
            console.log(action.payload)
            state.drawingToolSelected = action.payload
        },
        clearReviewVideoURL: (state) => {
            state.reviewVideoURL = emptyVideoURI
        },
        // updateReviewVideoURL: (state) => {
        //     state.reviewVideoURL = getReviewVideoUrl()
        // },
        updateRecordingInProgress: (state, action:PayloadAction<string>) => {
            state.currentlyRecordingClip = action.payload
        },
        cancelRecordingInProgress: (state) => {
            state.currentlyRecordingClip = ""
        },
        newV2Review: (state, action:PayloadAction<VideoUpdate>) => {
            state.status = ""
            state.mainPaneVideoURI = {mp4Src: action.payload.uri.mp4Src, hlsSrc: action.payload.uri.hlsSrc}
            state.submissionVideo = action.payload.uri
            state.reviewPoints = []
            state.mainPaneVideoType = VideoType.submission
            state.submissionVideo = action.payload.uri
            state.submissionVideoSpot = 0
        },
        updateStatus: (state, action:PayloadAction<string>) => {
            state.status = action.payload
        },
        updateSpot: (state, action:PayloadAction<number>) => {
            state.submissionVideoSpot = action.payload
        },
        updateMainPaneVideo: (state, action:PayloadAction<VideoUpdate>) => {
            console.log(action.payload)
            if(action.payload.type === VideoType.intro && state.recordedIntro.uri === ""){
               setIsIntro(true)
            }else if(action.payload.type === VideoType.summary && state.recordedSummary.uri === "") {
                setIsIntro(true)
            }else{
                setIsIntro(false)
            }
            state.mainPaneVideoURI = action.payload.uri
            state.mainPaneVideoType = action.payload.type
            if(action.payload.playList && !!action.payload.playList.length){
                state.playList = action.payload.playList
            }else{
                state.playList = []
            }
        },
        addReviewPoint: (state, action:PayloadAction<reviewPoint>) => {
            state.reviewPoints.push(action.payload)
        },
        removeRecordedSegment: (state, action:PayloadAction<RecordedSegment>) => {
            //100 and 200 are reserved for Intro and Summary
            if(isIntroSegment(action.payload.label)){
                state.recordedIntro = {
                    label: "Intro",
                    uri: "",
                    uploadStatus: ""
                } as RecordedSegment
            }
            if(isSummarySegment(action.payload.label)){
                state.recordedSummary = {
                    label: "Summary",
                    uri: "",
                    uploadStatus: ""
                } as RecordedSegment
            }
            state.recordedSegments = state.recordedSegments.filter(rs => rs.label !== action.payload.label)
        },
        addRecordedSegment: (state, action:PayloadAction<RecordedSegment>) => {
            //100 and 200 are reserved for Intro and Summary
            if(isNormalSegment(action.payload.label)){
                state.recordedSegments.push(action.payload)
            }
        },
        updateRecordedSegmentUri: (state, action:PayloadAction<RecordedSegment>) => {
            if(isIntroSegment(action.payload.label)){
                state.recordedIntro = action.payload
            }else if(isSummarySegment(action.payload.label)){
                state.recordedSummary = action.payload
            }else {
                state.recordedSegments = state.recordedSegments.map(rs => {
                    if(rs.label === action.payload.label){
                        console.log("found it")
                        console.log(action.payload)
                        return action.payload
                    }else
                        return rs
                })
            }
        },
       updateReviewPoint: (state, action:PayloadAction<reviewPoint>) => {
           console.log("updating to ")
           console.log(action.payload)
           state.reviewPoints = state.reviewPoints.map(rp => {
                if(rp.creationDate === action.payload.creationDate){
                    console.log("found it")
                    return action.payload
                }else
                    return rp
            })
        },
        updateCurrentReviewPoint: (state, action:PayloadAction<reviewPoint|undefined>) => {
            state.currentReviewPoint = action.payload
        },
        setClipHasRecording: (state, action:PayloadAction<number>) => {
            state.reviewPoints = state.reviewPoints.map(rp => {
                console.log(rp)
                if(rp.creationDate === action.payload){
                    console.log("found it")
                    return {...rp, hasRecording: true}
                }else
                    return rp
            })
        },
        setClearClipRecording: (state, action:PayloadAction<number>) => {
            state.reviewPoints = state.reviewPoints.map(rp => {
                console.log(rp)
                if(rp.creationDate === action.payload){
                    console.log("found it")
                    return {...rp, hasRecording: false}
                }else
                    return rp
            })
        },
        updateReviewPointTip: (state, action:PayloadAction<reviewPoint>) => {
            state.currentReviewPoint = action.payload
            state.reviewPoints = state.reviewPoints.map(rp => {
                if(rp.creationDate === action.payload.creationDate){
                    console.log("found it")
                    return action.payload
                }else
                    return rp
            })
        },
        removeReviewPoint: (state, action:PayloadAction<number>) => {
            state.reviewPoints = state.reviewPoints.filter(rp => {
                return rp.creationDate !== action.payload
            });
        },
        updateLastReviewRequestId: (state, action:PayloadAction<string>) => {
            state.lastReviewRequestId = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAccessZoneTargetReviewRequestThunk.fulfilled, feedbackResponseReducer
            )
        .addCase(getPlayerProfileThunk.fulfilled, (state: reviewtoolState, action: PayloadAction<ViaUserProfile>) => {
            console.log("Player", action.payload)
            state.player = action.payload
        })
        .addCase(updateCoachFeedbackThunk.fulfilled, feedbackResponseReducer)
    },
});


export const {toggleShowSubmissionDetails, updateSavedSegments, updateLastReviewRequestId, removeRecordedSegment, setMainPaneVideoDuration, updatePaused, updateRecordedSegmentUri, addRecordedSegment, updateDrawingTool, setClipHasRecording, setClearClipRecording, cancelRecordingInProgress, updateRecordingInProgress, clearReviewVideoURL, newV2Review, updateStatus, updateMainPaneVideo, addReviewPoint, updateReviewPoint, updateCurrentReviewPoint, removeReviewPoint, updateSpot, updateReviewPointTip} = reviewtoolSlice.actions;
export default reviewtoolSlice.reducer

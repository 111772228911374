import React from 'react'
import NorthWestIcon from '@mui/icons-material/NorthWest';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {updateDrawingTool, VideoType} from "./reviewtoolSlice";
import {DrawingTool, redoDrawingShape, undoDrawingShape} from "../../lib/recordingCanvas";
import {Button} from "@mui/material";


export function DrawingToolbox() {

    const dispatch = useAppDispatch()
    const drawingToolSelected = useAppSelector<DrawingTool>((state: RootState) => state.reviewTool.drawingToolSelected)
    const mainPaneVideoType = useAppSelector<VideoType>((state: RootState) => state.reviewTool.mainPaneVideoType)
    const paused = useAppSelector<boolean>((state: RootState) => state.reviewTool.paused)

    const enableButtons = mainPaneVideoType === VideoType.submission && paused

    return (
        <div className={"drawing-toolbox" + (enableButtons ? "" : " disabled")}>
            <Button
                variant="text"
                color="secondary"
                disabled={!enableButtons}
                className="drawing-button"
                onClick={() => {undoDrawingShape()}}>
                    <UndoIcon/>
            </Button>
            <Button
                variant="text"
                color="secondary"
                disabled={!enableButtons}
                className="drawing-button"
                onClick={() => {redoDrawingShape()}}>
                <RedoIcon/>
            </Button>
            <Button
                variant="text"
                color="secondary"
                disableRipple={true}
                disabled={!enableButtons}
                onClick={() => {dispatch(updateDrawingTool("CIRCLE"))}}>
                <PanoramaFishEyeIcon
                    style={{color: drawingToolSelected === "CIRCLE" && enableButtons  ? "#BA840C" : "inherit"}}/>
            </Button>
            <Button
                variant="text"
                color="secondary"
                disableRipple={true}
                disabled={!enableButtons}
                onClick={() => {dispatch(updateDrawingTool("LINE"))}}>
                <HorizontalRuleIcon
                    style={{color: drawingToolSelected === "LINE" && enableButtons  ? "#BA840C" : "inherit"}}/>
            </Button>
            <Button
                variant="text"
                color="secondary"
                disableRipple={true}
                disabled={!enableButtons}
                onClick={() => {dispatch(updateDrawingTool("ARROW"))}}>
                <NorthWestIcon
                    style={{color: drawingToolSelected === "ARROW" && enableButtons ? "#BA840C" : "inherit"}}/>
            </Button>
        </div>


    )

}
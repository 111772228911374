import React from "react";
import {CoachFeedbackItemStatus, ReviewRequestObject} from "../accessZoneSlice";
import {TMActionButton} from "./TMActionButton";
import {DialogContentText} from "@mui/material";
import {useAppDispatch} from "../../../redux/hooks";
import {
    CoachFeedbackUpdatePayload,
    CoachFeedbackUpdateRequest,
    updateCoachFeedbackThunk
} from "../../reviewtool/reviewtoolSlice";

interface TMAButtonAdminReturnToCoachProps {
    reviewRequest: ReviewRequestObject
}

export function TMAButtonAdminReturnToCoach(props: TMAButtonAdminReturnToCoachProps) {

    const dispatch = useAppDispatch()

    const returnStatus: CoachFeedbackItemStatus =
        props.reviewRequest.feedback && props.reviewRequest.feedback.media.length > 0
            ? CoachFeedbackItemStatus.IN_REVIEW
            : CoachFeedbackItemStatus.REVIEW_PENDING

    return (
        <TMActionButton
            reviewRequest={props.reviewRequest}
            actionButtonLabel="Return To Coach"
            actionConfirmButtonLabel="Return To Coach"
            dialogueTitle="Communicate your reason on Slack"
            dialogueContent={
                <DialogContentText id="alert-dialog-description" className="confirm-modal-background">
                    <p>E.g. Feedback can still be created despite the issue that was raised against the user submission video.</p>
                </DialogContentText>
            }
            actionFn={
                () => {
                    dispatch(updateCoachFeedbackThunk({
                        uuid: props.reviewRequest.uuid,
                        payload: { status: returnStatus } as CoachFeedbackUpdatePayload,
                        window_redirect_to: "/access-zone"
                    } as CoachFeedbackUpdateRequest))
                }
            }
        />
    )
}

import {
    MediaUploadResponse,
} from "../features/media/mediaSlice";


export const putVideo = (uploadResponse: MediaUploadResponse, file: File, onSuccessCallback: () => void): void => {
    console.log(file)
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadResponse.url);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                onSuccessCallback()
            } else {
                console.log('Upload failure!');
            }
        }
    };
    xhr.send(file);
}
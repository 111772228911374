import React, {useEffect} from 'react'
import {CircularProgress} from "@mui/material";
import {signOut} from "../../services/aws/Cognito";
import {useAppDispatch} from "../../redux/hooks";
import {clearProfile} from "./userSlice";


export function LogoutFromApp() {

    const dispatch = useAppDispatch()

    useEffect(() => {
        const signOutOfApp = async () => {
            await signOut()
        }

        signOutOfApp().then(
            ()=>{
                sessionStorage.clear()
                dispatch(clearProfile())
                window.location.assign("/")
            }
        )

        return () => {
            // this now gets called when the component unmounts
        };

    },[dispatch])

    return(
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'stretch'}}>
            <CircularProgress/>
        </div>
    )

}
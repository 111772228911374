import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getS3urls, S3urlsRequest, createViaAcademyPost, getViaAcademyPosts} from "../../services/api";
import {UserProfileStub} from "../user/userSlice";

export type OrientationTypes = "VERTICAL"|"HORIZONTAL"

export interface ViaS3MediaFile {
    mime_type: string
    url: string
    key: string
    orientation: OrientationTypes
}

export interface MediaVideo extends ViaS3MediaFile{
    frame_image: ViaS3MediaFile
    stream: ViaS3MediaFile
}

export interface MediaObject {
    video: MediaVideo
}

export interface UploadFilename {
    original: string
    cdnFilename: string
    localFilePath: string
}

export interface MediaUploadResponse {
    url: string
    key: string
    cdn_base_url: string
}

export type SubscriptionLevels = "free"|"pro"

export interface VideoKey {
    key: string
    mime_type: string
}

export interface ViaAcademyPostCreate {
    subscription_level: SubscriptionLevels
    title: string
    description: string
    video: VideoKey
}

export interface ViaAcademyPost {
    uuid: string
    title: string
    subscription_level: SubscriptionLevels
    description: string
    created_at: number // receiving 10-digit time from server
    posted_by: UserProfileStub
    media: MediaObject
}

// const sampleTZPost =
// {
//     "uuid": "1c7a9688-00cf-441a-8266-e053eef26b96",
//     "created_at": 1677220063,
//     "posted_by": {
//         "uuid": "17e0173f-1185-4487-ab43-4c0f3cba9493",
//         "handle": "KzAdminNeil",
//         "avatar": "https://qa-via.kz-cdn.net/media/defaults/avatar.png"
//     },
//     "subscription_level": "free",
//     "title": "title",
//     "description": "description",
//     "media": {
//         "video": {
//             "frame_image": {
//                 "mime_type": "image/jpeg",
//                 "url": "https://qa-via.kz-cdn.net/media/b826a934-d087-4b9c-ade5-04dccf7c3231/255046148deb4d529635eed629968d58_first_frame.jpg",
//                 "key": "media/b826a934-d087-4b9c-ade5-04dccf7c3231/255046148deb4d529635eed629968d58_first_frame.jpg",
//                 "orientation": "HORIZONTAL"
//             },
//             "stream": {
//                 "mime_type": "application/x-mpegURL",
//                 "url": "https://qa-via.kz-cdn.net/media/b826a934-d087-4b9c-ade5-04dccf7c3231/255046148deb4d529635eed629968d58.m3u8",
//                 "key": "media/b826a934-d087-4b9c-ade5-04dccf7c3231/255046148deb4d529635eed629968d58.m3u8",
//                 "orientation": "HORIZONTAL"
//             },
//             "mime_type": "video/mp4",
//             "url": "https://qa-via.kz-cdn.net/media/b826a934-d087-4b9c-ade5-04dccf7c3231/255046148deb4d529635eed629968d58.mp4",
//             "key": "media/b826a934-d087-4b9c-ade5-04dccf7c3231/255046148deb4d529635eed629968d58.mp4",
//             "orientation": "HORIZONTAL"
//         }
//     },
//     "views": 0
// } as ViaAcademyPost


interface mediaState {
    uploadFilename?: UploadFilename
    uploadResponse?: MediaUploadResponse
    uploadProgress: Number
    editingPost: ViaAcademyPost | undefined
    contentPosts: ViaAcademyPost[]
    postingMedia: boolean
}



// Define the initial state using that type
const initialState: mediaState = {
    uploadFilename: undefined,
    uploadResponse: undefined,
    uploadProgress: 0,
    editingPost: undefined, //sampleTZPost
    contentPosts: [],
    postingMedia: false
}


export const getS3urlsThunk = createAsyncThunk<MediaUploadResponse, S3urlsRequest>(
    'media/getS3urls',
    async (args, thunkAPI) => {
        console.log(thunkAPI)
        const gotten = await getS3urls(args);
        return gotten as MediaUploadResponse;
    }
)

export const createViaAcademyPostThunk = createAsyncThunk<ViaAcademyPost, ViaAcademyPostCreate>(
    'media/createViaAcademyPost',
    async (args, thunkAPI) => {
        console.log(thunkAPI)
        const gotten = await createViaAcademyPost(args);
        console.log(gotten);
        return gotten as ViaAcademyPost;
    }
)

// export const updateViaAcademyPostThunk = createAsyncThunk<ViaAcademyPost, ViaAcademyPost>(
//     'media/createViaAcademyPost',
//     async (args, thunkAPI) => {
//         console.log(thunkAPI)
//         const gotten = await updateViaAcademyPost(args);
//         console.log(gotten);
//         return gotten as ViaAcademyPost;
//     }
// )


export const getViaAcademyPostsThunk = createAsyncThunk<ViaAcademyPost[], void>(
    'media/getViaAcademyPostsThunk',
    async (args: void, thunkAPI) => {
        console.log(thunkAPI)
        const gotten = await getViaAcademyPosts();
        console.log(gotten);
        return gotten
    }
)

export const mediaSlice = createSlice({
    name: 'media',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        cancelUpdatingPost: (state: mediaState, action: PayloadAction<void>) => {
            console.log(action)
            state.editingPost = undefined
            state.uploadResponse = undefined
        },
        updateFilename: (state: mediaState, action: PayloadAction<UploadFilename>) => {
            state.uploadResponse = undefined
            state.uploadFilename = action.payload
        },
        updateUploadResponse: (state: mediaState, action: PayloadAction<MediaUploadResponse>) => {
            state.uploadResponse = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getS3urlsThunk.fulfilled, (state: mediaState, action: PayloadAction<MediaUploadResponse>) => {
            console.log(action.payload)
            state.uploadResponse = {
                url: action.payload.url,
                key: action.payload.key,
                cdn_base_url: action.payload.cdn_base_url
            } as MediaUploadResponse
            state.postingMedia = true
        })
        .addCase(createViaAcademyPostThunk.fulfilled, (state: mediaState, action: PayloadAction<ViaAcademyPost>) => {
            console.log(action.payload)
            state.editingPost = action.payload as ViaAcademyPost|undefined
            state.postingMedia = false
        })
        .addCase(getViaAcademyPostsThunk.fulfilled, (state: mediaState, action: PayloadAction<ViaAcademyPost[]>) => {
            console.log(action.payload)
            state.contentPosts = action.payload
        })
    },
});


export const {updateUploadResponse, updateFilename, cancelUpdatingPost} = mediaSlice.actions;
export default mediaSlice.reducer

import React from "react";
import {CoachFeedbackItemStatus, ReviewRequestObject} from "../accessZoneSlice";
import {TMActionButton} from "./TMActionButton";
import {DialogContentText} from "@mui/material";
import {useAppDispatch} from "../../../redux/hooks";
import {
    CoachFeedbackUpdatePayload,
    CoachFeedbackUpdateRequest,
    updateCoachFeedbackThunk
} from "../../reviewtool/reviewtoolSlice";


interface TMAButtonReportIssueProps {
   reviewRequest: ReviewRequestObject
}

export function TMAButtonReportIssue(props: TMAButtonReportIssueProps) {

    const dispatch = useAppDispatch()

    return (
        <TMActionButton
            reviewRequest={props.reviewRequest}
            actionButtonLabel="Report Issue"
            actionConfirmButtonLabel="Report Issue"
            dialogueTitle="Notes"
            dialogueContent={
                <DialogContentText id="alert-dialog-description" className="confirm-modal-background">
                    <p>PLEASE COMMUNICATE YOUR REASON FOR REJECTION ON SLACK</p>
                    <p>E.g. Low quality video, can’t identify the user, etc.</p>
                </DialogContentText>
            }
            actionFn={
                () => {
                    dispatch(updateCoachFeedbackThunk({
                        uuid: props.reviewRequest.uuid,
                        payload: {status: CoachFeedbackItemStatus.SENT_ISSUE_TO_ADMIN} as CoachFeedbackUpdatePayload,
                        window_redirect_to: "/access-zone"
                    } as CoachFeedbackUpdateRequest))
                }
            }
        />
    )
}

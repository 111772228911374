import React, {useState} from 'react'
import {Button, DialogActions} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {ReviewRequestObject} from "../accessZoneSlice";

type ViaButtonVariant = "text" | "outlined" | "contained" | undefined

export interface TMActionButtonProps {
    reviewRequest: ReviewRequestObject
    actionButtonLabel: string
    actionConfirmButtonLabel: string
    actionConfirmCancelButtonLabel?: string
    dialogueTitle: string
    dialogueContent: React.ReactElement
    actionFn: () => void
    disabled?: boolean
    variant?: ViaButtonVariant
}



export function TMActionButton(props: TMActionButtonProps) {

    const [confirmAction, setConfirmAction] = useState<boolean>(false)

    const disabled = props.disabled === undefined ? false : props.disabled
    const variant = props.variant ? props.variant : "text"

    return (
        <>
            <Button className={ variant !== "text" ? "" : "reject-button"} variant={variant} onClick={() => setConfirmAction(true)} disabled={disabled}>{props.actionButtonLabel}</Button>
            <Dialog
                className="confirm-modal"
                // style={{backgroundColor: "#1E1E1E"}}
                open={confirmAction}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="confirm-modal-background">
                    {props.dialogueTitle}
                </DialogTitle>
                <DialogContent className="confirm-modal-background">
                    {props.dialogueContent}
                </DialogContent>
                <DialogActions className="confirm-modal-background">
                    <Button className="reject-confirm-button" onClick={props.actionFn}>{props.actionConfirmButtonLabel}</Button>
                    <Button className="reject-cancel-button" onClick={() => setConfirmAction(false)} autoFocus>
                        { props.actionConfirmCancelButtonLabel ? props.actionConfirmCancelButtonLabel : "Cancel"}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}
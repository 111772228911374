import React, {useEffect, useState} from 'react'
import {Chip, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {
    CFTabName,
    CoachFeedbackItemStatus,
    displayCoachFeedbackItemStatus,
    FeedPageParams,
    FeedTableRequestResult,
    getAccessZoneReviewRequestsThunk,
} from "./accessZoneSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {ReviewToolViewMode} from "../reviewtool/reviewtoolSlice";
import {
    cfViewingPermissionByRole,
    formatSecondsToDateString,
    getTabStatuses,
    isProcessingFailure
} from "../../lib/utils";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Pagination from '@mui/material/Pagination';

// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface FeedTableProps {
    viewMode: ReviewToolViewMode
    tabName: CFTabName
    isAdmin: boolean
    isModerator: boolean
    isCoach: boolean
}

function VideoStatusChip(status: CoachFeedbackItemStatus ) {
    return (
        <>
            {status.toString() === CoachFeedbackItemStatus.REVIEW_PENDING ?
                <span className="content-feed-thumbnail-play-button" ><PlayCircleOutlineIcon/></span> :
                <>
                    {status.toString() === CoachFeedbackItemStatus.IN_REVIEW ?
                        <Chip className="content-feed-thumbnail-status"
                              label={displayCoachFeedbackItemStatus(status.toString())} color="primary"
                              variant="outlined"/>
                        :
                        <Chip className="content-feed-thumbnail-status"
                              label={displayCoachFeedbackItemStatus(status.toString())} color="secondary"
                              variant="outlined"/>
                    }
                </>
            }
        </>
    )
}

export default function FeedTable(props: FeedTableProps) {

    const items_per_page = 20
    const table_feed = useAppSelector<FeedTableRequestResult>((state: RootState) => state.accessZone.table_feed)
    // const isCoach = useAppSelector<boolean>((state: RootState) => state.user.isCoach)
    const dispatch = useAppDispatch()
    const [currentPage, setCurrentPage] = useState(1)

    const tabName = props.tabName
    const isAdmin = props.isAdmin
    const isModerator = props.isModerator
    const isCoach = props.isCoach


    useEffect(() => {
        dispatch(getAccessZoneReviewRequestsThunk({statuses: getTabStatuses(tabName), pageParams: {page: 1, pageSize: items_per_page} as FeedPageParams}))
    },[dispatch, isAdmin, tabName])

    const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setCurrentPage(newPage)
        dispatch(getAccessZoneReviewRequestsThunk({statuses: getTabStatuses(tabName), pageParams: {page: newPage, pageSize: items_per_page} as FeedPageParams}))
    };

    return (
        <>
            <Table style={{width: "100%"}}>
                <TableHead>
                    <TableRow>
                        {/*<TableCell className="video-cell"><Checkbox/></TableCell>*/}
                        <TableCell>VIDEO</TableCell>
                        <TableCell></TableCell>
                        <TableCell>USER</TableCell>
                        <TableCell>
                            {props.tabName === CFTabName.COACH_TODO ?
                                <>LAST EDITED BY</>
                                :
                                <>COACH</>
                            }
                        </TableCell>
                        {/*{!isCoach && <TableCell>Academy</TableCell>}*/}
                        <TableCell>Academy</TableCell>
                        <TableCell>
                            {props.tabName === CFTabName.REVIEW_COMPLETE || props.tabName === CFTabName.SENT_ISSUE_TO_USER ?
                                <>Sent</>
                                :
                                <>Due</>
                            }
                        </TableCell>
                        {/*<TableCell>V1 Review Tool</TableCell>*/}
                        {/*{props.viewMode === "Coach" && <TableCell>Coach Tool</TableCell>}*/}
                        {/*{props.viewMode === "Moderator" && <TableCell>Moderator Tool</TableCell>}*/}
                        {/*{props.viewMode === "Admin" && <TableCell>Admin Tool</TableCell> }*/}
                        {/*<TableCell>Debug Info</TableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { table_feed.results.map( (v,i) => {

                        let status = v.status

                        const [canOpen, openModeUrlSegment ] = cfViewingPermissionByRole(props.viewMode, status, isCoach, isModerator, isAdmin)

                        const sentDate = v.feedback && v.feedback.sign_off_date !== null ? formatSecondsToDateString(v.feedback.sign_off_date) : "-"

                        return (

                        <TableRow key={i} className="content-video-feed-row">
                            {/*<TableCell>*/}
                            {/*    <Checkbox/>*/}
                            {/*</TableCell>*/}
                            <TableCell className="video-cell">
                                { v.video && !!v.video.video.frame_image && !!v.video.video.frame_image.url && !isProcessingFailure(status) ?
                                    <>
                                    <span className={"content-feed-thumbnail-container" + (canOpen ? " clickable-cursor" : "")}  onClick={
                                        () => {
                                            if (canOpen)
                                                window.location.assign(openModeUrlSegment + v.uuid)
                                        }
                                    }>
                                        <img className="content-feed-thumbnail" src={v.video.video.frame_image.url}
                                             alt={"submission from " + v.user.handle}/>
                                        { VideoStatusChip(status)}
                                    </span>
                                    </>
                                    :
                                    <span>Video processing, please wait...</span>
                                }
                                { isProcessingFailure(status) &&
                                    <>
                                        <p>[{status}]: {v.uuid}</p>
                                        <p><a href={"/access-zone/submission-review/" + v.uuid}>View Submission Video</a></p>
                                        <p><a href={"/access-zone/feedback-review/" + v.uuid}>View Feedback Video</a>
                                        </p>
                                    </>
                                }
                            </TableCell>
                            <TableCell className="display-name-cell">
                                <span className="display-name-text">{v.display_name}</span><br/>
                                <span className="display-name-text">{v.order_source}</span>

                            </TableCell>
                            <TableCell sx={{verticalAlign: "top"}}>
                                <span style={{whiteSpace: "nowrap"}}>{v.order_name ? v.order_name : v.user.first_name + " " + v.user.last_name}</span><br/>
                                {v.order_age && v.order_age > 0 &&
                                    <><span style={{whiteSpace: "nowrap"}}>Age: {v.order_age}</span><br/></>
                                }
                                {v.order_position &&
                                    <><span style={{whiteSpace: "nowrap"}}>Position: {v.order_position}</span><br/></>
                                }
                                {!!v.identification_notes &&
                                    <div>Identifier: {v.identification_notes}</div>
                                }
                            </TableCell>
                            <TableCell>
                                { v.feedback ?
                                    <span>{v.feedback.reviewer.first_name} {v.feedback.reviewer.last_name}</span>
                                    :
                                    <span><em>unassigned</em></span>
                                }
                            </TableCell>
                            {/*{!isCoach &&*/}
                                <TableCell>
                                    {v.organisation.name === "FFC Academy" &&
                                        <img alt="FFC Academy Logo" className="feed-academy-logo" src="/club_logos/ffc_logo.png"/>
                                    }
                                    {v.organisation.name === "QPR Academy" &&
                                        <img alt="QPR ACADEMY logo" className="feed-academy-logo" src="/club_logos/qpr_logo.png"/>
                                    }
                                    {v.organisation.name === "PUFC Academy" &&
                                        <img alt="PUFC Academy logo" className="feed-academy-logo" src="/club_logos/pb_logo.png"/>
                                    }
                                    {v.organisation.name === "Via Dev Academy" &&
                                        <img alt="Via Dev Academy" className="feed-academy-logo" src="/club_logos/via_logo.svg"/>
                                    }
                                </TableCell>
                            {/*}*/}
                            <TableCell>
                                {props.tabName === CFTabName.REVIEW_COMPLETE || props.tabName === CFTabName.SENT_ISSUE_TO_USER ?
                                    <>{sentDate}</>
                                    :
                                    <>{v.due_days_remaining} days</>
                                }
                            </TableCell>
                            {/*{props.viewMode === "Coach" &&*/}
                            {/*    <TableCell>*/}
                            {/*        {v.video && !!v.video.video.frame_image && !!v.video.video.frame_image.url ?*/}
                            {/*            <Button onClick={() => {*/}
                            {/*                window.location.assign("/access-zone/review/" + v.uuid)*/}
                            {/*            }} variant="contained">Coach Review</Button>*/}
                            {/*            :*/}
                            {/*            <Button variant="contained" disabled={true}>Coach Review</Button>*/}
                            {/*        }*/}
                            {/*    </TableCell>*/}
                            {/*}*/}
                            {/*{props.viewMode === "Moderator" &&*/}
                            {/*    <TableCell>*/}
                            {/*        {v.video && !!v.video.video.frame_image && !!v.video.video.frame_image.url ?*/}
                            {/*            <Button onClick={() => {*/}
                            {/*                window.location.assign("/access-zone/feedback-review/" + v.uuid)*/}
                            {/*            }} variant="contained">Moderator Review</Button>*/}
                            {/*            :*/}
                            {/*            <Button variant="contained" disabled={true}>Moderator Review</Button>*/}
                            {/*        }*/}
                            {/*    </TableCell>*/}
                            {/*}*/}
                            {/*{props.viewMode === "Admin" &&*/}
                            {/*    <TableCell>*/}
                            {/*        {v.video && !!v.video.video.frame_image && !!v.video.video.frame_image.url ?*/}
                            {/*            <Button onClick={() => {*/}
                            {/*                window.location.assign("/access-zone/submission-review/" + v.uuid)*/}
                            {/*            }} variant="contained">Admin Review</Button>*/}
                            {/*            :*/}
                            {/*            <Button variant="contained" disabled={true}>Admin Review</Button>*/}
                            {/*        }*/}
                            {/*    </TableCell>*/}
                            {/*}*/}
                            {/*<TableCell>*/}
                            {/*    <p>id: <code>{v.uuid}</code></p>*/}
                            {/*    <p>video_key: <code>{v.video.video.key}</code></p>*/}
                            {/*    <p>video_url: <a href={v.video.video.url}>{v.video.video.url}</a></p>*/}
                            {/*    <p>uploaded_by: <code>{v.user.handle}</code></p>*/}
                            {/*    <p>uploaded_time: <code>{new Date(v.created_at * 1000).toISOString()}</code></p>*/}
                            {/*    <p>due_days_remaining: <code>{v.due_days_remaining}</code></p>*/}

                            {/*    <p>media clips: </p>*/}
                            {/*    <ul>{ !!v.feedback && !!v.feedback.media.length ?*/}
                            {/*        <>*/}
                            {/*            {v.feedback.media.map((s: ViaCoachFeedbackReviewMedia) => {*/}
                            {/*                return (*/}
                            {/*                    <li>{s.video.label}: <code>{s.video.key}</code> / <a*/}
                            {/*                        href={s.video.url}>url</a></li>*/}
                            {/*                )*/}
                            {/*            })}*/}
                            {/*        </>*/}
                            {/*        : <li>no feedback created</li>*/}
                            {/*    }</ul>*/}
                            {/*</TableCell>*/}
                        </TableRow>)}
                    )}
                </TableBody>
            </Table>
            <br/>

            <Stack alignItems="center">
                <Pagination count={Math.ceil((table_feed.count / items_per_page))} page={currentPage} onChange={handlePageChange} variant="outlined" shape="rounded"/>
            </Stack>
        </>
    )
}
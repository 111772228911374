import React from 'react'
import ReviewRequestFeed from "../features/access-zone/ReviewRequestFeed";

export default function AccessZone() {

    return (
        <ReviewRequestFeed/>
    )


}
import React from 'react'

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {Box, Drawer, Toolbar} from "@mui/material";
import SubscriptionsSharpIcon from '@mui/icons-material/SubscriptionsSharp';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import {useNavigate} from "react-router-dom";


const drawerWidth = 240;


export default function SideMenu(){

    const navigate = useNavigate()

    return (
        <Drawer
            id="main-drawer"
            sx={{
                marginTop: "64px",
                width: drawerWidth,
                flexShrink: 0,
                paper: {background: "#081111"},
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent" anchor="left">
            <Toolbar/>
            <Box sx={{overflow: 'auto'}}>
                <List>
                    <ListItem key='AccessZone' disablePadding>
                        <ListItemButton onClick={() => navigate("/access-zone")} selected={window.location.href.includes("/access-zone")}>
                            <ListItemIcon>
                                <SubscriptionsSharpIcon/>
                            </ListItemIcon>
                            <ListItemText primary='Coach Feedback'/>
                        </ListItemButton>
                    </ListItem>
                    <Divider/>
                    <Divider/>
                </List>
                <List style={{ position: "absolute", bottom: "0", width: "100%"}}>
                    <Divider/>
                    <ListItem key='Settings' disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <SettingsSharpIcon/>
                            </ListItemIcon>
                            <ListItemText primary='Settings'/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    )
}
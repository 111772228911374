import React from 'react'
import {useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {ViaUserProfile} from "../user/userSlice";
import {ReviewRequestObject} from "../access-zone/accessZoneSlice";
// import {getAgeFromPythonTimestamp, renderPlayingPosition} from "../../lib/utils";
// import {formatSecondsToISO} from "../../lib/utils";
// import {KeyboardArrowDown, KeyboardArrowLeft} from "@mui/icons-material";


export const SubmissionDetails = (): JSX.Element => {

    const playerProfile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.reviewTool.player)
    const reviewRequest = useAppSelector<ReviewRequestObject|undefined>((state: RootState) => state.reviewTool.reviewRequest)
    const showSubmissionDetails = useAppSelector<boolean>((state: RootState) => state.reviewTool.showSubmissionDetails)

    // const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            {
                showSubmissionDetails && playerProfile && reviewRequest ?
                    <div className="submission-details">
                        <div>
                            <div><span className="submission-detail-label">Name</span><span>{reviewRequest.order_name}</span></div>
                            <div><span className="submission-detail-label">Age</span><span>{reviewRequest.order_age}</span></div>
                            <div><span className="submission-detail-label">Position</span><span>{reviewRequest.order_position}</span></div>
                            <div><span className="submission-detail-label">Identifier</span><span>{reviewRequest.identification_notes}</span></div>
                            {/*<div><span className="submission-detail-label">uuid</span><span>{playerProfile.uuid}</span></div>*/}
                        </div>
                        {/*<div>*/}
                            {/*<div><span className="submission-detail-label">Shirt Color</span><span>-</span></div>*/}
                            {/*<div><span className="submission-detail-label">Position</span><span>{playerProfile.playing_position}</span></div>*/}
                        {/*</div>*/}
                        <div>
                            {
                                reviewRequest.improvement_areas.map((c, i) => {
                                    return (
                                        <div key={"ia-" + i}>
                                            <span className="submission-detail-label">Point {i + 1}</span>
                                            <span>{c.detail}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : null
            }
        </>
    )
}
import React from 'react'
import {DrawingToolbox} from "./DrawingToolbox";
import {CloseOutlined, KeyboardArrowDown, KeyboardArrowLeft} from "@mui/icons-material";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {ViaUserProfile} from "../user/userSlice";
import {RootState} from "../../redux/store";
import {CoachFeedbackItemStatus, ReviewRequestObject} from "../access-zone/accessZoneSlice";
import {
    ReviewToolViewMode,
    toggleShowSubmissionDetails
} from "./reviewtoolSlice";
import IconButton from "@mui/material/IconButton";
import {
    Avatar,
    Button
} from "@mui/material";
import {TMAButtonReportIssue} from "../access-zone/actionButtons/TMAButtonReportIssue";
import {TMAButtonSendToModerator} from "../access-zone/actionButtons/TMAButtonSendToModerator";
import {TMAButtonModeratorApprove} from "../access-zone/actionButtons/TMAButtonModeratorApprove";
import {TMAButtonModeratorReject} from "../access-zone/actionButtons/TMAButtonModeratorReject";
import {TMAButtonAdminReturnToCoach} from "../access-zone/actionButtons/TMAButtonAdminReturnToCoach";
import {TMAButtonAdminReturnToUser} from "../access-zone/actionButtons/TMAButtonAdminReturnToUser";
import {isProcessingFailure} from "../../lib/utils";

export interface TitleBarProps {
    viewMode: ReviewToolViewMode
}

export function TitleBar(props: TitleBarProps) {

    const dispatch = useAppDispatch()
    const playerProfile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.reviewTool.player)
    const reviewRequest = useAppSelector<ReviewRequestObject|undefined>((state: RootState) => state.reviewTool.reviewRequest)
    const showSubmissionDetails = useAppSelector<boolean>((state: RootState) => state.reviewTool.showSubmissionDetails)
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile)

    const isAdmin = useAppSelector<boolean>((state: RootState) => state.user.isAdmin)
    const isModerator = useAppSelector<boolean>((state: RootState) => state.user.isModerator)
    const isCoach = useAppSelector<boolean>((state: RootState) => state.user.isCoach)


    function stringToColor(string: string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <div id="title-bar">
            {props.viewMode === "ReviewTool" ? <DrawingToolbox/> : <span></span> }
            {playerProfile && reviewRequest &&
                <div
                    className="submission-details-title"
                >
                    <span className="username-toggle" onClick={() => dispatch(toggleShowSubmissionDetails())}>{reviewRequest.order_name ? reviewRequest.order_name : (playerProfile.user.first_name + " " + playerProfile.user.last_name)}{
                        reviewRequest ? " / " + reviewRequest.display_name + "": ""
                    }</span>
                    <IconButton disableRipple={true} onClick={() => dispatch(toggleShowSubmissionDetails())}>
                        {showSubmissionDetails ? <KeyboardArrowDown/> : <KeyboardArrowLeft/>}
                    </IconButton>
                </div>
            }
            <div className="title-bar-right">
                {profile &&
                    <Avatar {...stringAvatar(profile.user.first_name + " " + profile.user.last_name)} />
                }
                {props.viewMode === "SubmissionReview" && isAdmin
                    && reviewRequest && reviewRequest.status.toString() !== CoachFeedbackItemStatus.SENT_ISSUE_TO_USER &&
                    <>
                    {
                        reviewRequest.status.toString() !== CoachFeedbackItemStatus.IN_REVIEW
                        && reviewRequest.status.toString() !== CoachFeedbackItemStatus.MODERATOR_REJECTED
                        && reviewRequest.status.toString() !== CoachFeedbackItemStatus.REVIEW_PENDING
                        &&
                            <>
                                <TMAButtonAdminReturnToUser reviewRequest={reviewRequest} />
                                { reviewRequest && !isProcessingFailure(reviewRequest.status) &&
                                    <TMAButtonAdminReturnToCoach reviewRequest={reviewRequest}/>
                                }
                            </>
                    }
                    </>
                }
                {props.viewMode === "FeedbackReview" && isAdmin && reviewRequest &&
                    reviewRequest.status.toString() === CoachFeedbackItemStatus.SENT_TO_MODERATOR &&
                    <>
                        <TMAButtonModeratorReject reviewRequest={reviewRequest}/>
                        <TMAButtonModeratorApprove reviewRequest={reviewRequest}/>
                    </>
                }
                {props.viewMode === "FeedbackReview" && isModerator && reviewRequest
                    && reviewRequest.status.toString() !== CoachFeedbackItemStatus.REVIEW_COMPLETE &&
                    <>
                        <TMAButtonModeratorReject reviewRequest={reviewRequest}/>
                        <TMAButtonModeratorApprove reviewRequest={reviewRequest}/>
                    </>
                }
                {props.viewMode === "ReviewTool" && isCoach && reviewRequest &&
                    <>
                        <TMAButtonReportIssue reviewRequest={reviewRequest} />
                        <TMAButtonSendToModerator reviewRequest={reviewRequest}/>
                    </>
                }
                <Button id="review-tool-close-button" onClick={()=>{window.location.assign("/access-zone")}}><CloseOutlined/></Button>
            </div>
        </div>
    )
}
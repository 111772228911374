import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {viatheme} from "./viaMuiTheme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import { Provider } from 'react-redux';
import {store} from "./redux/store";


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
              <ThemeProvider theme={viatheme}>
                  <CssBaseline/>
                  <App/>
              </ThemeProvider>
          </BrowserRouter>
      </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getAccessZoneReviewRequests} from "../../services/api";


type MediaOrientation = "VERTICAL" | "HORIZONTAL"

export enum CFTabName {
    COACH_TODO,
    SENT_ISSUE_TO_ADMIN,
    PROCESSING_FAILURE,
    SENT_ISSUE_TO_USER,
    SENT_TO_MODERATOR,
    REJECTED_BY_MODERATOR,
    REVIEW_COMPLETE
}

export enum CoachFeedbackItemStatus {
    REVIEW_PENDING = 'REVIEW_PENDING',
    IN_REVIEW = 'IN_REVIEW',
    PROCESSING = 'PROCESSING',
    PROCESSING_FAILURE = 'PROCESSING_FAILURE',
    FEEDBACK_PROCESSING_FAILURE = 'FEEDBACK_PROCESSING_FAILURE',
    SENT_TO_MODERATOR = 'SENT_TO_MODERATOR',
    SENT_ISSUE_TO_ADMIN = 'SENT_ISSUE_TO_ADMIN',
    MODERATOR_REJECTED = 'MODERATOR_REJECTED',
    REVIEW_COMPLETE = 'REVIEW_COMPLETE',
    SENT_ISSUE_TO_USER = 'SENT_ISSUE_TO_USER',
    REJECT_USER_UNIDENTIFIABLE = 'REJECT_USER_UNIDENTIFIABLE',
    REJECT_USER_VIDEO_QUALITY_ISSUE = 'REJECT_USER_VIDEO_QUALITY_ISSUE',
    REJECT_AMBIGUOUS_IMPROVEMENT_AREAS = 'REJECT_AMBIGUOUS_IMPROVEMENT_AREAS',
    REJECT_OTHER_ISSUE = 'REJECT_OTHER_ISSUE'
}

export const displayCoachFeedbackItemStatus = (status: string): string => {
    let s: string
    if(status === CoachFeedbackItemStatus.MODERATOR_REJECTED){
        s = "REJECTED BY MODERATOR"
    }else{
        s = status
    }
    return s.replace(/_/g, " ")
}

export interface ViaStreamObject {
    mime_type: string
    url?: string
    key: string
    orientation: MediaOrientation
}

export interface ViaImageObject {
    mime_type: string
    url?: string
    key: string
    orientation?: MediaOrientation
}

export interface ViaVideoObject {
    mime_type: string
    url?: string
    key: string
    orientation?: MediaOrientation
    frame_image?: ViaImageObject
}

export interface CoachFeedBackClip {
    mime_type: string
    url?: string
    key: string
    orientation?: MediaOrientation
    frame_image: ViaImageObject
    label: string
}

export interface ViaCoachFeedbackReviewMedia {
    video: CoachFeedBackClip
}

export interface ViaCoachFeedback {
    reviewer: ViaCoachFeedbackReviewer
    created_at: number
    video: ViaVideoObject
    stream: ViaStreamObject
    media: ViaCoachFeedbackReviewMedia[]
    sign_off_date: number
}

export interface ViaCoachFeedbackReviewer {
    uuid: string
    first_name: string
    last_name: string
}

export interface ReviewRequestVideo {
    video: ViaVideoObject
    stream: ViaStreamObject
}

export interface ImprovementArea {
    detail: string
}

export interface CoachFeedbackOrderItem {
    id: string
    display_name: string
}

export interface CoachFeedbackOrder{
    id: string
    created_at: number
    item: CoachFeedbackOrderItem
}

export interface OrganisationObject {
    abbreviation: string
    category: number
    name: string
    uuid: string
}

export interface ReviewRequestObject {
    uuid: string
    display_name: string
    created_at: number
    user: ViaUser
    organisation: OrganisationObject
    status: CoachFeedbackItemStatus
    due_days_remaining: number
    others_in_video: true
    order_name: string|null
    order_age: number|null
    order_position: string|null
    order_source: string|null
    identification_notes: string
    improvement_areas: ImprovementArea[]
    order: CoachFeedbackOrder
    video: ReviewRequestVideo
    feedback?: ViaCoachFeedback
}

export interface ViaUser {
    uuid: string
    // actually being returned:
    handle: string
    avatar: string
    first_name: string
    last_name: string
    // backend out of sync with swagger. as if using swagger:
    // profile: ViaUserProfile
}

export interface FeedPageParams {
    page: number
    pageSize: number
}

export interface FeedTableRequestParams {
    pageParams: FeedPageParams
    statuses: string[]
}

export interface FeedTableRequestResult {
    count: number
    next: number
    previous: number
    results: ReviewRequestObject[]
}


export const getAccessZoneReviewRequestsThunk = createAsyncThunk<FeedTableRequestResult, FeedTableRequestParams >(
    'access-zone/getAccessZoneReviewRequestsThunk',
    async (args, thunkAPI) => {
        console.log(thunkAPI)
        const gotten = await getAccessZoneReviewRequests(args);
        console.log(gotten);
        return gotten
    }
)

interface accessZoneState {
    feed_page: number
    items_per_page: number
    table_feed: FeedTableRequestResult

}

const initialTableFeed: FeedTableRequestResult = {
    count: 1,
    previous: 1,
    next: 1,
    results: []
}

const initialState: accessZoneState = {
    feed_page: 1,
    items_per_page: 10,
    table_feed: initialTableFeed
}

export const accessZoneSlice = createSlice({
    name: 'accessZone',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        clearReviewRequests: (state: accessZoneState) => {
            state.table_feed = initialTableFeed
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAccessZoneReviewRequestsThunk.fulfilled, (state: accessZoneState, action: PayloadAction<FeedTableRequestResult>) => {
            state.table_feed = action.payload
        })
    },
});

export const {clearReviewRequests} = accessZoneSlice.actions;
export default accessZoneSlice.reducer

import React, {useEffect, useRef} from 'react'
import {
    setCoachCamRef,
    setCoachCamStream,
    stopCoachCamStream
} from "../../lib/userMedia";

export function CoachCam() {

    const coachCamRef = useRef<HTMLVideoElement>(null)

    useEffect(()=> {
        //video stream
        window.navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
        }).then(camStream => {
                // @ts-ignore
                coachCamRef.current.srcObject = camStream;
                setCoachCamStream(camStream);
                setCoachCamRef(coachCamRef.current as HTMLVideoElement)
            })
            .catch(() => {
                alert('You have to give browser the permission to run Webcam and Mic;( ');
                stopCoachCamStream()
            });

    }, [])

    return (
        <div id='webcam-player-container'>
            <video ref={coachCamRef} id="webcam-player" muted autoPlay ></video>
        </div>
    )

}
import {
    CoachFeedbackUploadClip,
    RecordedSegment,
    reviewPoint,
    ReviewToolViewMode
} from "../features/reviewtool/reviewtoolSlice";
import {MediaUploadResponse} from "../features/media/mediaSlice";
import {
    CFTabName,
    CoachFeedBackClip,
    CoachFeedbackItemStatus,
    ViaCoachFeedback
} from "../features/access-zone/accessZoneSlice";
import {getLastRecordingLengthString} from "./recordingCanvas";

//seconds are in python's timestamp
export const formatSecondsToISO = (s: number): string => {
    const msEpoch = (Math.round(s * 1000)/1000) * 1000
    if(!Number.isInteger(msEpoch))
        return "--:--"
    else
        return new Date(msEpoch).toISOString().slice(14, 19);
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"
];

//seconds are in python's timestamp
export const formatSecondsToDateString = (s: number): string => {
    const date = new Date(s * 1000)

    return (date.getHours() < 10 ? '0' : '') + date.getHours().toString()
        + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes().toString()
        + " " + (date.getDate() < 10 ? '0' : '') + date.getDate().toString()
        + "/" + (date.getMonth() < 10 ? '0' : '') + date.getMonth()
        + "/" + date.getFullYear().toString()
}

export const getDurationFromLabel = (l:string): number | undefined => {
    let parts = l.split("-")
    if(parts.length > 2 && parseInt(parts[2]) !== undefined)
        return parseInt(parts[2])
    else return undefined
}

//seconds are from js timestamp
export const labelSecondsToTime = (l: string): string|false => {
    let parts = l.split("-")
    let lengthString = ""
    if(parts.length < 2)
        return false
    if(parts.length > 2)
        lengthString = formatSecondsToISO(parseInt(parts[2])) + " "

    const labelDate = new Date(parseInt(parts[1]))

    return lengthString + (labelDate.getHours() < 10 ? '0' : '') + labelDate.getHours().toString()
        + ":" + (labelDate.getMinutes() < 10 ? '0' : '') + labelDate.getMinutes().toString()
        + ":" + (labelDate.getSeconds() < 10 ? '0' : '') +  labelDate.getSeconds().toString()
        + " " + labelDate.getDate().toString() + " " + monthNames[labelDate.getMonth()];
}

export const createClipSrc = (rp: reviewPoint, uri: string): string  => {
    return uri + "#t=" + rp.start + "," + rp.end
}


// const videosHaveProcessedKey = (key: string, videos: ViaCoachFeedbackReviewMedia[]): boolean => {
//     const existing = videos.filter(v => v.video.key === key && v.video.url !== undefined)
//     return !!existing.length;
// }

export const addCoachFeedBackClipToVideos = (currentlyRecordingLabel: string, s3urls: MediaUploadResponse, savedSegments: RecordedSegment[], recordedSegments: RecordedSegment[]): CoachFeedbackUploadClip[] => {
    const segments = savedSegments.concat(recordedSegments)
    let uploadClips: CoachFeedbackUploadClip[] = segments.filter(s=> s.key !== undefined).map(s=> { return {
        key: s.key,
        mime_type: "video/mp4",
        label: s.label
    } as CoachFeedbackUploadClip})
    uploadClips.push({ label: currentlyRecordingLabel + "-" + getLastRecordingLengthString(), key: s3urls.key, mime_type: "video/mp4" } as CoachFeedbackUploadClip)

    return uploadClips
}

export const removeCoachFeedBackClipToVideos = (label: string, s3urls: MediaUploadResponse, videos: CoachFeedBackClip[]): CoachFeedbackUploadClip[] => {
    const v_clips: CoachFeedbackUploadClip[] = videos.filter(v => v.label !== label ).map(v=> v as CoachFeedbackUploadClip)
    console.log(v_clips)
    return v_clips
}

interface SavedKeyURLPair {
    key: string
    url: string
}

export const updateSavedRecordedSegments = (segments: RecordedSegment[], feedback: ViaCoachFeedback): RecordedSegment[] => {
    const saved_keys: SavedKeyURLPair[] = feedback.media.filter(v => v.video.url !== undefined).map(v => {return {key: v.video.key, url: v.video.url || ""}} )
    const updatedSegments = segments.map(s => {
        let newUrl: string = ""
        if( s.key !== undefined
            && !!saved_keys.filter(
            k => {
                if (k.key === s.key) {
                    newUrl = k.url
                    return true
                } else {
                    return false
                }
            })
            .length
        ){
            return {
                ...s,
                uploadStatus: "Saved",
                url: newUrl
            }
        }else{
            return s
        }
    })
    console.log(updatedSegments)
    return updatedSegments
}

export const removeRecordedThatAreSaved = (savedSegments: RecordedSegment[], recordedSegments: RecordedSegment[]): RecordedSegment[] => {
    const savedLabels = savedSegments.map(s => s.label.split('-')[1])
    return recordedSegments.filter(s => savedLabels.indexOf(s.label.split('-')[1]) === -1)
}

export const isIntroSegment = (segmentLabel: string): boolean => {
    return segmentLabel.slice(0,5) === "Intro"
}

export const isSummarySegment = (segmentLabel: string): boolean => {
    return segmentLabel.slice(0,7) === "Summary"
}

export const isNormalSegment = (segmentLabel: string): boolean => {
    return segmentLabel.slice(0,8) === "Standard"
}

export const isNonStandardSegment = (segmentLabel: string): boolean => {
    return segmentLabel.slice(0,8) !== "Standard"
}

const getPortString = (): string => {
    return window.location.port === "80" || window.location.port === "443" ? "" : ":" + window.location.port
}

export const getHostnameWithProtocolAndPort = (): string => {
    return window.location.protocol + "//" + window.location.hostname + getPortString()
}

export const getCrossOriginAttributeVideo = (objectUri: string): "anonymous"|undefined => {
    return objectUri.indexOf(getHostnameWithProtocolAndPort()) === -1 ? "anonymous" : undefined
}

export const getCrossOriginAttributeImg = (objectUri: string): string|null => {
    return objectUri.indexOf(getHostnameWithProtocolAndPort()) === -1 ? "Anonymous" : null
}

export const hasRequiredSavedSegments = (segments: RecordedSegment[]): boolean => {
    let hasIntro = false
    let hasRegular = false
    let hasSummary = false
    segments.forEach(s => {
        if(isIntroSegment(s.label)){
           hasIntro = true
        }else if(isNormalSegment(s.label)){
            hasRegular = true
        }else if(isSummarySegment(s.label)){
            hasSummary = true
        }
    })

    return hasIntro && hasRegular && hasSummary
}

// export const feedTableRowFilter = (isAdmin: boolean, tabName: CFTabName, cf: ReviewRequestObject): boolean => {
//     if(tabName === CFTabName.USER_VIDEOS){
//         if(isAdmin)
//             return true
//         else
//             return cf.status === "REVIEW_PENDING"
//                 || cf.status === "IN_REVIEW"
//                 || cf.status === "PROCESSING"
//                 || cf.status === "PROCESSING_FAILURE"
//     }else if(tabName === CFTabName.REVIEWED){
//         return cf.status === "SENT_TO_QA"
//             || cf.status === "PROCESSING"
//             || cf.status === "IN_QA"
//     }else if(tabName === CFTabName.REJECTED){
//         return cf.status === "SUBMISSION_REJECTED"
//     }else if(tabName === CFTabName.SENT){
//         return cf.status === "REVIEWED"
//     }else
//         return true
// }

export const getTabStatuses = (tabName: CFTabName): string[] => {
    if(tabName === CFTabName.COACH_TODO){
        return [
            CoachFeedbackItemStatus.IN_REVIEW,
            CoachFeedbackItemStatus.REVIEW_PENDING,
            CoachFeedbackItemStatus.MODERATOR_REJECTED
        ]
    }else if(tabName === CFTabName.SENT_ISSUE_TO_ADMIN) {
        return [
            CoachFeedbackItemStatus.SENT_ISSUE_TO_ADMIN
        ]
    }else if(tabName === CFTabName.PROCESSING_FAILURE) {
        return [
            CoachFeedbackItemStatus.PROCESSING_FAILURE,
            CoachFeedbackItemStatus.FEEDBACK_PROCESSING_FAILURE
        ]
    }else if(tabName === CFTabName.SENT_ISSUE_TO_USER){
        return [
            CoachFeedbackItemStatus.REJECT_USER_UNIDENTIFIABLE,
            CoachFeedbackItemStatus.REJECT_USER_VIDEO_QUALITY_ISSUE,
            CoachFeedbackItemStatus.REJECT_AMBIGUOUS_IMPROVEMENT_AREAS,
            CoachFeedbackItemStatus.REJECT_OTHER_ISSUE,
            CoachFeedbackItemStatus.SENT_ISSUE_TO_USER
        ]
    }else if(tabName === CFTabName.SENT_TO_MODERATOR){
        return [
            CoachFeedbackItemStatus.SENT_TO_MODERATOR
        ]
    }else if(tabName === CFTabName.REJECTED_BY_MODERATOR){
        return [
            CoachFeedbackItemStatus.MODERATOR_REJECTED
        ]
    }else if(tabName === CFTabName.REVIEW_COMPLETE){
        return [
            CoachFeedbackItemStatus.REVIEW_COMPLETE
        ]
    }else
        return []
}

export const arraySlideForward = (arr: any[], index: number): any[] => {

    if(index === 0)
        return arr

    return arr.map((item, i) => {
        if(i === index - 1){
            return arr[index]
        }else if(i === index){
            return arr[index-1]
        }else
            return item
    })
}

export const arraySlideBack = (arr: any[], index: number): any[] => {

    if(index === arr.length - 1)
        return arr

    return arr.map((item, i) => {
        if(i === index + 1){
            return arr[index]
        }else if(i === index){
            return arr[index+1]
        }else
            return item
    })
}

export const savedSegmentToCFVideo = (arr:RecordedSegment[]):CoachFeedbackUploadClip[] => {

    return arr.map( s => {
        return {
            key: s.key,
            mime_type: "video/mp4",
            label: s.label
        } as CoachFeedbackUploadClip
    })

}

export const isProcessingFailure = (status: CoachFeedbackItemStatus): boolean => {
    return status.includes("PROCESSING_FAILURE")
}

export const cfViewingPermissionByRole = (vm: ReviewToolViewMode,
                                          cfStatus: CoachFeedbackItemStatus,
                                          isCoach: boolean,
                                          isModerator: boolean,
                                          isAdmin: boolean): [boolean, string] => {
    const reviewToolUrlSegment = "/access-zone/review/"
    const submissionVideoUrlSegment = "/access-zone/submission-review/"
    const feedbackVideoUrlSegment = "/access-zone/feedback-review/"

    if (vm === "ReviewTool"){
        if (isAdmin && cfStatus.toString() === CoachFeedbackItemStatus.REVIEW_PENDING){
            return [true, submissionVideoUrlSegment]
        }
        if (isAdmin && cfStatus.toString() === CoachFeedbackItemStatus.IN_REVIEW && isAdmin){
            return [false, ""]
        }
        return [true, reviewToolUrlSegment]
    }
    if (vm === "FeedbackReview"){
        if(isCoach && cfStatus.toString() === CoachFeedbackItemStatus.MODERATOR_REJECTED){
            return [true, reviewToolUrlSegment]
        }
        return [true, feedbackVideoUrlSegment]
    }
    if (vm === "SubmissionReview"){
        return [true, submissionVideoUrlSegment]
    }

    return [true, submissionVideoUrlSegment]
}

export const getAgeFromPythonTimestamp = (ts: number): string => {
    const dob = new Date(ts * 1000)
    const now = new Date(Date.now())
    console.log(dob.toISOString())
    return (now.getFullYear() - dob.getFullYear()) + ""
}

export const renderPlayingPosition = (p: string): string => {
    return p.replace('CBL', 'CB')
}



import React from "react";
import {CoachFeedbackItemStatus, ReviewRequestObject} from "../accessZoneSlice";
import {TMActionButton} from "./TMActionButton";
import {DialogContentText} from "@mui/material";
import {useAppDispatch} from "../../../redux/hooks";
import {
    CoachFeedbackUpdatePayload,
    CoachFeedbackUpdateRequest,
    updateCoachFeedbackThunk
} from "../../reviewtool/reviewtoolSlice";


interface TMAButtonModeratorApproveProps {
    reviewRequest: ReviewRequestObject
}

export function TMAButtonModeratorApprove(props: TMAButtonModeratorApproveProps) {

    const dispatch = useAppDispatch()

    return (
        <TMActionButton
            reviewRequest={props.reviewRequest}
            actionButtonLabel="Approve"
            actionConfirmButtonLabel="Yes, send to user"
            actionConfirmCancelButtonLabel="No, don't send"
            dialogueTitle="Send to User?"
            dialogueContent={
                <DialogContentText id="alert-dialog-description" className="confirm-modal-background">
                    <p>Are you sure you want to send the Coach Feedback to user?</p>
                </DialogContentText>
            }
            actionFn={
                () => {
                    dispatch(updateCoachFeedbackThunk({
                        uuid: props.reviewRequest.uuid,
                        payload: {status: CoachFeedbackItemStatus.REVIEW_COMPLETE} as CoachFeedbackUpdatePayload,
                        window_redirect_to: "/access-zone"
                    } as CoachFeedbackUpdateRequest))
                }
            }
        />
    )
}

import React, {useState} from "react";
import {CoachFeedbackItemStatus, ReviewRequestObject} from "../accessZoneSlice";
import {
    Button, Chip,
    DialogActions,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextareaAutosize
} from "@mui/material";
import {useAppDispatch} from "../../../redux/hooks";
import {
    CoachFeedbackUpdatePayload,
    CoachFeedbackUpdateRequest,
    updateCoachFeedbackThunk
} from "../../reviewtool/reviewtoolSlice";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";


interface TMAButtonAdminReturnToUserProps {
    reviewRequest: ReviewRequestObject
}

export function TMAButtonAdminReturnToUser(props: TMAButtonAdminReturnToUserProps) {

    const dispatch = useAppDispatch()
    const [confirmingDeliver, setConfirmingDeliver] = useState(false)
    const [rejectStatus, setRejectStatus] = useState<string>("")
    const [rejectDetail, setRejectDetail] = useState<string>("")

    const hashtags = [
        "#reject_user_unidentifiable",
        "#reject_user_video_quality_issue",
        "#reject_ambiguous_improvement_areas"
    ]

    const chipVariant = (body: string, needle: string):"outlined"|undefined => {
        if(body.includes(needle))
            return undefined
        else
            return "outlined"
    }

    const updateDetail = (body: string, hashtag: string):string => {
        if(body.includes(hashtag)){
            const re = new RegExp(" " + hashtag,"g")
            const re2 = new RegExp(hashtag,"g")
            return body.replace(re, "").replace(re2, "")
        }
        else
            return body + " " + hashtag
    }

    const disableConfirm = rejectStatus === CoachFeedbackItemStatus.REJECT_OTHER_ISSUE && rejectDetail === ""

    return (
        <>
            <Button className="" onClick={() => {setConfirmingDeliver(true)}} disabled={false} >Report Issue</Button>
            <Dialog
                className="confirm-modal"
                // style={{backgroundColor: "#1E1E1E"}}
                open={confirmingDeliver}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className="confirm-modal-background">
                    Rejection Note To User
                </DialogTitle>
                <DialogContent className="confirm-modal-background">
                    <FormControl
                        style={{minWidth: "380px", maxWidth:"500px", marginTop: "0.5em", marginBottom: "0.5em"}}
                    >
                        <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={rejectStatus}
                            label="Reason"
                            onChange={event =>
                                setRejectStatus(event.target.value)
                            }
                        >
                            <MenuItem value={CoachFeedbackItemStatus.REJECT_USER_UNIDENTIFIABLE}>Can't identify player</MenuItem>
                            <MenuItem value={CoachFeedbackItemStatus.REJECT_USER_VIDEO_QUALITY_ISSUE}>Video is too low quality</MenuItem>
                            <MenuItem value={CoachFeedbackItemStatus.REJECT_AMBIGUOUS_IMPROVEMENT_AREAS}>Don't understand improvement areas</MenuItem>
                            <MenuItem value={CoachFeedbackItemStatus.REJECT_OTHER_ISSUE}>Multiple issues</MenuItem>
                        </Select>
                    </FormControl>
                    {/*'#reject_user_unidentifiable'*/}
                    {/*'#reject_user_video_quality_issue'*/}
                    {/*'#reject_ambiguous_improvement_areas'*/}

                    <DialogContentText id="alert-dialog-description" className="confirm-modal-background">
                        <TextareaAutosize
                            className={rejectStatus === CoachFeedbackItemStatus.REJECT_OTHER_ISSUE ? "hide-issue-textarea" : "" }
                            value={rejectDetail}
                            onChange={ event => {
                                setRejectDetail(event.target.value)
                            }}
                            style={{backgroundColor: "#414141", minWidth: "380px", maxWidth:"500px"}}
                            minRows={10}
                        />
                    </DialogContentText>
                    { rejectStatus === CoachFeedbackItemStatus.REJECT_OTHER_ISSUE ?
                        hashtags.map(ht => {
                            return (
                                <span onClick={() => {
                                    setRejectDetail(updateDetail(rejectDetail, ht))
                                }}>
                                            <Chip label={ht} variant={chipVariant(rejectDetail, ht)}/>
                                        </span>
                            )
                        })
                        : null
                    }
                </DialogContent>
                <DialogActions className="confirm-modal-background">
                    <Button
                        disabled={disableConfirm}
                        className={disableConfirm ? "reject-confirm-button-disable" : "reject-confirm-button"}
                        onClick={() => {
                            dispatch(updateCoachFeedbackThunk({
                                uuid: props.reviewRequest.uuid,
                                payload: {status: rejectStatus, detail: rejectDetail} as CoachFeedbackUpdatePayload,
                                window_redirect_to: "/access-zone"
                            } as CoachFeedbackUpdateRequest))
                        }}>Report Issue (Return To User)</Button>
                    <Button className="reject-cancel-button" onClick={() => setConfirmingDeliver(false)}
                            autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

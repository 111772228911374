import {createTheme} from "@mui/material";

// declare module '@mui/material/styles' {
//     interface Theme {
//         status: {
//             danger: string;
//         };
//     }
//     // allow configuration using `createTheme`
//     interface ThemeOptions {
//         status?: {
//             danger?: string;
//         };
//     }
// }

export const viatheme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#ba840c',
        },
        secondary: {
            main: '#EEEEEE',
        },
        warning: {
            main: '#ff6400',
        },
        info: {
            main: '#2196f3',
        },
    },
    // components: {
    //     MuiTypography: {
    //         defaultProps: {
    //             variantMapping: {
    //                 h1: 'h2',
    //                 h2: 'h2',
    //                 h3: 'h2',
    //                 h4: 'h2',
    //                 h5: 'h2',
    //                 h6: 'h2',
    //                 subtitle1: 'h2',
    //                 subtitle2: 'h2',
    //                 body1: 'span',
    //                 body2: 'span',
    //             },
    //         },
    //     },
    // },
})
import React from 'react'
import {configure, getLoggedInUser} from "./services/aws/Cognito";
import {Routes, Route, Navigate} from "react-router-dom";
// import {Home} from "./views/Home";
import {TopHeader} from "./views/TopHeader";
import "./viaStyles.scss"
import {useAppSelector} from "./redux/hooks";
import {RootState} from "./redux/store";
import {LoginForm} from "./features/user/LoginForm";
import {Box, Toolbar} from "@mui/material";
import SideMenu from "./views/SideMenu";
import ContentFeed from "./views/ContentFeed";
import {ViaUserProfile} from "./features/user/userSlice";
import {FullScreenReviewTool} from "./features/reviewtool/FullScreenReviewTool";
import AccessZone from "./views/AccessZone";
import {LogoutFromApp} from "./features/user/LogoutFromApp";



export default function App() {
    configure();
    const profile = useAppSelector<ViaUserProfile|undefined>((state: RootState) => state.user.profile);

    getLoggedInUser().then((user) => {
        if (user) {
          console.log('Logged in user attributes', user.attributes);
          console.log(sessionStorage.getItem("idToken"))
        } else {
          console.log('User is not logged in!');
        }
    });

    return (
        <div className="App">
            { profile === undefined &&
                <Routes>
                    <Route path="/logout" element={<LogoutFromApp/>}/>
                    <Route path="/*" element={<LoginForm/>}/>
                </Routes>
            }
            { profile !== undefined &&
                <Routes>
                    {/*<Route path="/" element={<AppLayout><Home/></AppLayout>}/>*/}
                    <Route path="/" element={<Navigate to="/access-zone" />}/>
                    <Route path="/access-zone" element={<AppLayout><AccessZone/></AppLayout>}/>
                    <Route path="/access-zone/review/:review_request_id" element={<FullScreenReviewTool viewMode="ReviewTool"/>}/>
                    <Route path="/access-zone/submission-review/:review_request_id" element={<FullScreenReviewTool viewMode="SubmissionReview"/>}/>
                    <Route path="/access-zone/feedback-review/:review_request_id" element={<FullScreenReviewTool viewMode="FeedbackReview"/>}/>
                    <Route path="/content" element={<AppLayout><ContentFeed/></AppLayout>}/>
                    <Route path="/logout" element={<LogoutFromApp/>}/>
                </Routes>
            }
        </div>
    );
}

interface AppLayoutProps {
    children: JSX.Element
}

function AppLayout(props: AppLayoutProps) {
    return (
        <Box sx={{display: 'flex'}}>
            <TopHeader/>
            <SideMenu/>
            <Box className="main-content-box">
                <Toolbar/>
                {props.children}
            </Box>
        </Box>
    )
}

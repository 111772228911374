
let coachCamStream: MediaStream|undefined = undefined
let coachCamRef: HTMLVideoElement;

export const getCoachCamStream = () => {
    return coachCamStream
}

export const getCoachCamVideoTracks = () => {
    if(coachCamStream)
        return coachCamStream.getVideoTracks()
    else
        return []
}

export const setCoachCamStream = (newStream: MediaStream) => {
    coachCamStream = newStream
}

export const getCoachCamAudioTracks = () => {
    if(coachCamStream)
        return coachCamStream.getAudioTracks()
    else
        return []
}

export const setCoachCamRef = (ccr: HTMLVideoElement) => {
    coachCamRef = ccr
}

export const getCoachCamRef = (): HTMLVideoElement => {
    return coachCamRef
}

export const stopCoachCamStream = () => {
    if(coachCamStream) {
        coachCamStream.getTracks().forEach(function (track) {
            console.log("stop track", track.id)
            track.stop();
        });
    }
}

// Copyright 2023 Kaizhan Ltd.

const AppConfig = {
    API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
    DEBUG_MODE: Boolean(process.env.REACT_APP_DEBUG_MODE),
    COGNITO: {
      OAUTH_CUSTOM_DOMAIN: process.env.REACT_APP_COGNITO_CUSTOM_HOSTNAME,
      USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      REGION: process.env.REACT_APP_COGNITO_REGION,
      CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID,
      OAUTH_LOGIN_REDIRECT: process.env.REACT_APP_OAUTH_LOGIN_REDIRECT
    }
};

export {
    AppConfig
}

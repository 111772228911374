import React from "react";
import {CoachFeedbackItemStatus, ReviewRequestObject} from "../accessZoneSlice";
import {TMActionButton} from "./TMActionButton";
import {DialogContentText} from "@mui/material";
import {useAppDispatch} from "../../../redux/hooks";
import {
    CoachFeedbackUpdatePayload,
    CoachFeedbackUpdateRequest,
    updateCoachFeedbackThunk
} from "../../reviewtool/reviewtoolSlice";


interface TMAButtonModeratorRejectProps {
    reviewRequest: ReviewRequestObject
}

export function TMAButtonModeratorReject(props: TMAButtonModeratorRejectProps) {

    const dispatch = useAppDispatch()

    return (
        <TMActionButton
            reviewRequest={props.reviewRequest}
            actionButtonLabel="Reject"
            actionConfirmButtonLabel="Reject (Return to Coach)"
            dialogueTitle="Communicate your reason on Slack"
            dialogueContent={
                <DialogContentText id="alert-dialog-description" className="confirm-modal-background">
                    <p>E.g. Feedback not specific enough, hard to watch</p>
                </DialogContentText>
            }
            actionFn={
                () => {
                    dispatch(updateCoachFeedbackThunk({
                        uuid: props.reviewRequest.uuid,
                        payload: { status: CoachFeedbackItemStatus.MODERATOR_REJECTED } as CoachFeedbackUpdatePayload,
                        window_redirect_to: "/access-zone"
                    } as CoachFeedbackUpdateRequest))
                }
            }
        />
    )
}

import React from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import FeedTable from "./FeedTable";
import {useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {CFTabName} from "./accessZoneSlice";
import {ReviewToolViewMode} from "../reviewtool/reviewtoolSlice";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

// function a11yProps(index: number) {
//     return {
//         id: `simple-tab-${index}`,
//         'aria-controls': `simple-tabpanel-${index}`,
//     };
// }

interface tabData {
    label: string
    tableViewMode: ReviewToolViewMode
    tabName: CFTabName
}

export default function ReviewRequestFeed() {

    const isAdmin = useAppSelector<boolean>((state: RootState) => state.user.isAdmin)
    const isModerator = useAppSelector<boolean>((state: RootState) => state.user.isModerator)
    const isCoach = useAppSelector<boolean>((state: RootState) => state.user.isCoach)

    const [value, setValue] = React.useState(0)

    let tabs: tabData[] = []

    if(isAdmin || isCoach)
        tabs.push({label: "Coach To Do", tableViewMode: "ReviewTool", tabName: CFTabName.COACH_TODO})
    if(isAdmin)
        tabs.push({label: "Processing Failure", tableViewMode: "SubmissionReview", tabName: CFTabName.PROCESSING_FAILURE})
    if(isAdmin || isCoach)
        tabs.push({label: "Sent Issue To Admin", tableViewMode: "SubmissionReview", tabName: CFTabName.SENT_ISSUE_TO_ADMIN})
    if(isAdmin)
        tabs.push({label: "Sent Issue To User", tableViewMode: "SubmissionReview", tabName: CFTabName.SENT_ISSUE_TO_USER})
    tabs.push({label: "Sent To Moderator", tableViewMode: "FeedbackReview", tabName: CFTabName.SENT_TO_MODERATOR})
    tabs.push({label: "Rejected By Moderator", tableViewMode: "FeedbackReview", tabName: CFTabName.REJECTED_BY_MODERATOR})
    tabs.push({label: "Review Complete", tableViewMode: "FeedbackReview", tabName: CFTabName.REVIEW_COMPLETE})


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <>
            <h1 style={{paddingLeft: "16px"}}>Coach Feedback: Task Manager</h1>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        variant="scrollable"
                        // scrollButtons
                        // allowScrollButtonsMobile
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example">
                            {tabs.map((t, i) => {
                                return (<Tab label={t.label} {...a11yProps(i)} key={"panelTab-" + i}/>)
                            })}
                    </Tabs>
                </Box>
                    {tabs.map((t, i) =>{
                        return (
                            <CustomTabPanel value={value} index={i} key={"tabPanel-"+i}>
                                <FeedTable isAdmin={isAdmin} isModerator={isModerator} isCoach={isCoach}
                                           viewMode={t.tableViewMode} tabName={t.tabName}/>
                            </CustomTabPanel>
                        )
                    })}
            </Box>
        </>
    )
}
import React, {useEffect, useRef, useState} from 'react'
import {
    emptyVideoURI,
    setMainPaneVideoDuration,
    updateMainPaneVideo,
    updatePaused,
    updateSpot,
    VideoType,
    VideoUpdate, VideoURI
} from "./reviewtoolSlice";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import {Range} from "react-range";
import {
    CanvasDimensions,
    drawCoachCam,
    DrawingMouseButtonPoint,
    DrawingTool,
    drawOntoDisplayCanvas,
    drawVideo,
    getDisplayCanvasDimensions,
    getRecordingCanvasDimensions,
    initCanvasRecorder,
    recordCanvasMouseButtonDown,
    recordCanvasMouseButtonUp,
    resetDrawings,
    setCanvasDimensions,
    setDisplayCanvas,
    setRecordingCanvas,
    clearButtonDownPoint
} from "../../lib/recordingCanvas";
import {getCoachCamStream} from "../../lib/userMedia";
import {RecordButton} from "./RecordButton";
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import IconButton from "@mui/material/IconButton";
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {formatSecondsToISO, getCrossOriginAttributeVideo} from "../../lib/utils";
import {HLSVideoPlayer} from "../../shared/HLSVideoPlayer";

interface CanvasScalingOffsets {
    xOffset: number
    yOffset: number
}

const updateSecondPoint = (dc: HTMLCanvasElement, ev: MouseEvent, drawingToolSelected: DrawingTool, inProgress: boolean = false): void => {
    const yOffsetLayout = (document.getElementById("title-bar") as HTMLDivElement).clientHeight
    const isWider: boolean = dc.width / dc.height > 16 / 9
    const scale = isWider ? 1080 / dc.height : 1920 / dc.width
    const canvasScalingOffsets: CanvasScalingOffsets = isWider ? {
            xOffset: (dc.width - (dc.height * (1920 / 1080))) / 2,
            yOffset: 0,
        }
        : {
            xOffset: 0,
            yOffset: (dc.height - (dc.width * (1080 / 1920))) / 2
        }
    if(
        ev.pageX >= canvasScalingOffsets.xOffset
        && ev.pageX <= dc.width - canvasScalingOffsets.xOffset
        && ev.pageY >= yOffsetLayout + canvasScalingOffsets.yOffset
        && ev.pageY <= (dc.height + yOffsetLayout) - canvasScalingOffsets.yOffset
    ){
        recordCanvasMouseButtonUp({
            x: (ev.pageX - canvasScalingOffsets.xOffset) * scale,
            y: (ev.pageY - canvasScalingOffsets.yOffset - yOffsetLayout) * scale,
            tool: drawingToolSelected
        } as DrawingMouseButtonPoint, inProgress)
    }

}



export function CoachMainPane() {

    const [slowMo, setSlowMo] = useState(false);
    const [videoDuration, setVideoDuration] = useState<undefined|number>(undefined);
    const dispatch = useAppDispatch()
    let videoRef = useRef<HTMLVideoElement>(null);
    let recordingCanvasRef = useRef<HTMLCanvasElement>(null);
    let displayCanvasRef = useRef<HTMLCanvasElement>(null);
    let mainPainRef = useRef<HTMLDivElement>(null);
    const [videoPlace, setVideoPlace] = useState([0]);

    const videoType = useAppSelector<VideoType>((state: RootState) => state.reviewTool.mainPaneVideoType)
    const videoSrc = useAppSelector<VideoURI>((state: RootState) => state.reviewTool.mainPaneVideoURI)
    const submissionVideo = useAppSelector<VideoURI>((state: RootState) => state.reviewTool.submissionVideo)
    // const submissionVideoSpot = useAppSelector<number>((state: RootState) => state.reviewTool.submissionVideoSpot)
    const resultVideoURL = useAppSelector<VideoURI>((state: RootState) => state.reviewTool.reviewVideoURL)
    const drawingToolSelected = useAppSelector<DrawingTool>((state: RootState) => state.reviewTool.drawingToolSelected)
    const currentlyRecordingClip = useAppSelector<string>((state: RootState) => state.reviewTool.currentlyRecordingClip)
    const playList = useAppSelector<VideoUpdate[]>((state: RootState) => state.reviewTool.playList)
    const paused = useAppSelector<boolean>((state: RootState) => state.reviewTool.paused)
    const mainPaneVideoDuration = useAppSelector<number|undefined>((state: RootState) => state.reviewTool.mainPaneVideoDuration)

    const [hlsPlayerSrc, setHLSPlayerSrc] = useState<VideoURI>(emptyVideoURI)


    useEffect(() => {
        const vRefCurrent = videoRef.current
        const handleLoadedMetadata = () => {
            if (videoRef.current) {
                console.log(`Video duration: ${videoRef.current.duration} seconds`);
                if(videoRef.current.duration !== Infinity){
                    setVideoDuration(Math.ceil(videoRef.current.duration * 100)/100)
                    // dispatch(setMainPaneVideoDuration(videoRef.current.duration))
                }else{
                    if(mainPaneVideoDuration)
                        setVideoDuration(Math.ceil(mainPaneVideoDuration * 100)/100)
                    else
                        setVideoDuration(360)
                }
            }
        };

        if (videoRef.current) {
            videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
        }

        return () => {
            if (vRefCurrent) {
                vRefCurrent.removeEventListener('loadedmetadata', handleLoadedMetadata);
            }
        };
    }, [videoRef, mainPaneVideoDuration]); // eslint-disable-line


    useEffect(()=> {
        console.log("videoDuration: ", videoDuration)
    }, [videoDuration])



    const playPause = () => {
        if(paused) {
            videoRef.current?.play();
            clearButtonDownPoint()
        } else {
            videoRef.current?.pause();
        }
    }

    // const restartVideo = () => {
    //     if(videoType === VideoType.submission && videoRef.current){
    //         videoRef.current.pause()
    //         videoRef.current.currentTime = 0
    //     }
    //     else if(videoType === VideoType.recordedClip && videoRef.current) {
    //         const pieces = videoSrc.split("t=")
    //         const startTime = pieces.length > 1 ? Number(pieces[1].split(',')[0]) : 0
    //         console.log(startTime)
    //         videoRef.current.pause()
    //         videoRef.current.currentTime = startTime
    //     }
    // }

    const normalSpeed = () => {
        setSlowMo(false)
        if(videoRef.current)
            videoRef.current.playbackRate = 1.0;
    }

    const halfSpeed = () => {
        setSlowMo(true)
        if(videoRef.current)
            videoRef.current.playbackRate = 0.5;
    }


    const updateSubmissionSpot = () => {
        if(videoType === VideoType.submission && videoRef.current){
            console.log(videoRef.current.currentTime)
            dispatch(updateSpot(videoRef.current.currentTime))
        }

    }

    const pauseVideo = () => {
        updateSubmissionSpot()
        dispatch(updatePaused(true))
    }

    const playVideo = () => {
        resetDrawings()
        updateSubmissionSpot()
        dispatch(updatePaused(false))
    }



    useEffect(()=> {
        if(videoRef.current) {
            console.log(videoRef.current.src)
            // console.log('duration when videoSrc changed:',videoRef.current.duration)
            // if(videoRef.current.duration !== Infinity)
            //     setVideoDuration(videoRef.current.duration)
            console.log(submissionVideo)
            // videoRef.current.src = videoSrc
            setHLSPlayerSrc(videoSrc)
            // videoRef.current.load()
            // videoRef.current.play().catch(error => {
            //     console.info('User has not interacted with document yet.')
            //     console.log(error)
            // })
            // setVideoDuration(videoRef.current.duration)
       }
    }, [videoSrc]) // eslint-disable-line

    const runInitCanvasRecorder = () => {
        if(getCoachCamStream())
            initCanvasRecorder()
        else
            setTimeout(runInitCanvasRecorder, 2000)

    }


    useEffect( () => {
        let dc: HTMLCanvasElement = displayCanvasRef.current as HTMLCanvasElement
        const handleMouseDown = (ev:MouseEvent) => {
            if(!paused){
                return
            }
            const yOffsetLayout = (document.getElementById("title-bar") as HTMLDivElement).clientHeight
            const isWider: boolean = dc.width / dc.height > 16 / 9
            const scale = isWider ? 1080 / dc.height : 1920 / dc.width
            const canvasScalingOffsets: CanvasScalingOffsets = isWider ? {
                    xOffset: (dc.width - (dc.height * (1920 / 1080))) / 2,
                    yOffset: 0,
                }
                : {
                    xOffset: 0,
                    yOffset: (dc.height - (dc.width * (1080 / 1920))) / 2
                }

            if(
                ev.pageX >= canvasScalingOffsets.xOffset
                && ev.pageX <= dc.width - canvasScalingOffsets.xOffset
                && ev.pageY >= yOffsetLayout + canvasScalingOffsets.yOffset
                && ev.pageY <= (dc.height + yOffsetLayout) - canvasScalingOffsets.yOffset
            ){
                recordCanvasMouseButtonDown({
                    x: (ev.pageX - canvasScalingOffsets.xOffset) * scale,
                    y: (ev.pageY - canvasScalingOffsets.yOffset - yOffsetLayout) * scale,
                    tool: drawingToolSelected
                } as DrawingMouseButtonPoint)
            }
        }
        const handleMouseUp = (ev: MouseEvent) => {
            updateSecondPoint(dc, ev, drawingToolSelected)
        }
        const handleMouseMove = (ev: MouseEvent) => {
            if(ev.buttons === 1 && drawingToolSelected !== "NONE") {
                updateSecondPoint(dc, ev, drawingToolSelected, true)
            }
        }
        if(dc){
            dc.addEventListener('mousedown', handleMouseDown)
            dc.addEventListener('mouseup', handleMouseUp)
            dc.addEventListener('mousemove', handleMouseMove)
        }
        return () => {
            if(dc){
                dc.removeEventListener('mousedown', handleMouseDown)
                dc.removeEventListener('mouseup', handleMouseUp)
                dc.addEventListener('mousemove', handleMouseMove)
            }
        }
    }, [drawingToolSelected, paused])

    useEffect( () =>  {
        let c: HTMLCanvasElement = recordingCanvasRef.current as HTMLCanvasElement
        let ctx = c ? c.getContext('2d') : null;
        let dc: HTMLCanvasElement = displayCanvasRef.current as HTMLCanvasElement
        let dctx = dc ? dc.getContext('2d') : null;
        let v: HTMLVideoElement = videoRef.current as HTMLVideoElement
        let mp: HTMLDivElement = mainPainRef.current as HTMLDivElement

        if(c && ctx){
            ctx.scale(4,4)
            setRecordingCanvas(c, ctx)
        }

        if(dc && dctx){
            dctx.scale(4,4)
            setDisplayCanvas(dc, dctx)
        }

        if(v && c){
            // const scale = Math.min(
            //     mp.clientWidth / v.videoWidth,
            //     (mp.clientHeight *.8) / v.videoHeight);
            dc.width = mp.clientWidth
            dc.height = mp.clientWidth * 9 / 16 // fix: not called on reload. (set somewhere else.)
            c.height = 1080
            c.width = 1920


            setCanvasDimensions({
                h: 1080,
                w: 1920,
                sourceWidth: v.videoWidth,
                sourceHeight: v.videoHeight,
                scale: Math.min(c.width / v.videoWidth, c.height / v.videoHeight)
            } as CanvasDimensions, "RECORDING")

            setCanvasDimensions({
                w: dc.width,
                h: dc.height,
                sourceWidth: c.width,
                sourceHeight: c.height,
                scale: Math.min(dc.width / c.width, dc.height / c.height)
            } as CanvasDimensions, "DISPLAY");

            console.log("add play listener")
            v.addEventListener('play', function() {
                // let $this = this; //cache
                (function loop() {
                    // if (!v.paused && !v.ended) {
                        if(ctx && v && c){
                            const rcd = getRecordingCanvasDimensions()
                            const dcd = getDisplayCanvasDimensions()
                            setCanvasDimensions({
                                ...rcd,
                                w: c.width,
                                h: c.height,
                                sourceWidth: v.videoWidth,
                                sourceHeight: v.videoHeight,
                                scale: Math.min(c.width / v.videoWidth, c.height / v.videoHeight)
                            }, "RECORDING");
                            if(dc && dctx){
                                setCanvasDimensions({
                                    ...dcd,
                                    w: dc.width,
                                    h: dc.height,
                                    sourceWidth: c.width,
                                    sourceHeight: c.height,
                                    scale: Math.min(dc.width / c.width, dc.height / c.height)
                                }, "DISPLAY");
                            }
                            drawVideo(v)
                        }
                    // }
                    drawCoachCam()
                    drawOntoDisplayCanvas()
                    setTimeout(loop, 1000 / 30); // drawing at 30fps (same as our encoded mp4)
                })();
            }, false);
            console.log("init recorder")
            runInitCanvasRecorder()
        }

    },[]) // eslint-disable-line

    return (
        <div ref={mainPainRef} id="coach-main-pane">
            {/*{ resultVideoURL &&*/}
            {/*    <video src={resultVideoURL} controls>Download Resulting Video</video>*/}
            {/*}*/}
            <canvas
                id="main-pane-display-canvas"
                className={videoType === VideoType.recordedClip ||
                videoType === VideoType.recordedIntro ||
                videoType === VideoType.recordedSummary ? "hide-for-clip" : ""}
                height={mainPainRef.current ? mainPainRef.current.clientHeight * 0.8 : 0}
                width={mainPainRef.current ? mainPainRef.current.clientWidth : 0}
                ref={displayCanvasRef}
            ></canvas>
            {resultVideoURL.mp4Src === "" &&
                <canvas
                    id="main-pane-canvas"
                    className={videoType === VideoType.recordedClip ||
                        videoType === VideoType.recordedIntro ||
                        videoType === VideoType.recordedSummary ? "hide-for-clip" : ""}
                    height={1920}
                    width={1080}
                    ref={recordingCanvasRef}
                ></canvas>
            }
            {resultVideoURL.mp4Src === "" &&
                <HLSVideoPlayer crossOrigin={getCrossOriginAttributeVideo(videoSrc.mp4Src)}
                       id="main-pane-video"
                       preload="auto"
                       className=""
                       mp4Src={hlsPlayerSrc.mp4Src}
                       hlsSrc={hlsPlayerSrc.hlsSrc}
                       videoRef={videoRef}
                       videoType={videoType}
                       onPause={pauseVideo}
                       onPlay={playVideo}
                       onTimeUpdate={event => {
                           if (event.currentTarget) {
                               // if (event.currentTarget.currentTime >= ((videoDuration ? videoDuration : 100))) {
                               //     if (videoRef.current) {
                               //         videoRef.current.pause();
                               //         restartVideo()
                               //     }
                               // }

                               // setVideoPlace([Math.floor(event.currentTarget.currentTime * 10)])
                               // setVideoPlace([parseFloat((event.currentTarget.currentTime * 10).toFixed(1))])
                               console.log(Math.round(event.currentTarget.currentTime * 10 * 10)/10)
                               setVideoPlace([Math.round(event.currentTarget.currentTime * 10 * 10)/10]) // Math.round(x * 10) / 10 -> one decimal place
                           }
                           // }} controls>
                       }}
                       onEnded={() => {
                           if (playList.length) {
                               dispatch(setMainPaneVideoDuration(playList[0].duration))
                               dispatch(updateMainPaneVideo({
                                   label: playList[0].label,
                                   uri: playList[0].uri,
                                   type: playList[0].type,
                                   playList: playList.slice(1)
                               }))
                           }
                       }}
                >
                    {/*<source src="" type="video/mp4"/>*/}
                    Your browser does not support the video tag.
                </HLSVideoPlayer>
            }
            <div className="video-controls">
                <div className="video-controls-top-row">
                    <span> <span style={{color: "#EEEEEE"}}>{formatSecondsToISO(videoPlace[0]/10)}</span>{videoDuration ? "/" : "" }<span style={{color: "#999999"}}>{videoDuration ? formatSecondsToISO(videoDuration) : ""}</span></span>

                    {slowMo ?
                        <IconButton
                            onClick={normalSpeed}
                            disableRipple={true}
                        >
                            <SlowMotionVideoIcon/>
                        </IconButton>
                        :
                        <IconButton
                            onClick={halfSpeed}
                            disableRipple={true}
                            style={{color: "#999999"}}
                        >
                            <SlowMotionVideoIcon/>
                        </IconButton>
                    }
                    <IconButton onClick={playPause} disableRipple={true}>
                        {paused ? <PlayArrowIcon/> : <PauseIcon/>}
                    </IconButton>
                    <RecordButton currentInProgressClip={currentlyRecordingClip} />
                </div>
                <div className="video-controls-bottom-level">
                    <Range
                        step={0.1}
                        min={0}
                        max={videoDuration ? videoDuration * 10 : 100}
                        rtl={false}
                        values={videoPlace}
                        onChange={(values) => {
                            if(videoRef.current) {
                                videoRef.current.currentTime = values[0] / 10
                            }
                        }}
                        renderTrack={({ props, children }) => (
                            <div
                                {...props}
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '4vh',
                                    height: '2.5vh',
                                    width: '98vw',
                                    marginLeft: '1vw',
                                    marginRight: '1vw',
                                    verticalAlign: 'top',
                                    backgroundColor: '#BA840C'
                                }}
                            >
                                {children}
                            </div>
                        )}
                        renderThumb={({ props }) => (
                            <div
                                {...props}
                                style={{
                                    height: '7vh',
                                    width: '1.25vw',
                                    // backgroundColor: '#000',
                                    backgroundImage: `url(${process.env.PUBLIC_URL + '/review_tool/Playhead.png'})`,
                                    backgroundPosition: 'top left',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: '100%'
                                }}
                            />
                        )}
                    />
                </div>
            </div>
        </div>
    )
}
import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup} from "@mui/material";
import {
    CoachFeedbackUpdatePayload, CoachFeedbackUpdateRequest, CoachFeedbackUploadClip,
    RecordedSegment,
    removeRecordedSegment, setMainPaneVideoDuration,
    updateCoachFeedbackThunk,
    updateMainPaneVideo, updateSavedSegments,
    VideoType,
    VideoURI,
    VideoUpdate
} from "./reviewtoolSlice";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import Divider from "@mui/material/Divider";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {RootState} from "../../redux/store";
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    arraySlideBack,
    arraySlideForward,
    formatSecondsToISO,
    isIntroSegment,
    isNormalSegment,
    isSummarySegment,
    labelSecondsToTime,
    savedSegmentToCFVideo
} from "../../lib/utils";
import {ReviewRequestObject} from "../access-zone/accessZoneSlice";


export function SidePanel(){

    const dispatch = useAppDispatch()
    const recordedIntro = useAppSelector<RecordedSegment>((state: RootState) => state.reviewTool.recordedIntro)
    const reviewRequest = useAppSelector<ReviewRequestObject|undefined>((state: RootState) => state.reviewTool.reviewRequest)
    const savedSegments = useAppSelector<RecordedSegment[]>((state: RootState) => state.reviewTool.savedSegments)
    const recordedSegments = useAppSelector<RecordedSegment[]>((state: RootState) => state.reviewTool.recordedSegments)
    const recordedSummary = useAppSelector<RecordedSegment>((state: RootState) => state.reviewTool.recordedSummary)
    const submissionVideo = useAppSelector<VideoURI>((state: RootState) => state.reviewTool.submissionVideo)
    const mainPaneVideoUri = useAppSelector<VideoURI>((state: RootState) => state.reviewTool.mainPaneVideoURI)
    const mainPaneVideoType = useAppSelector<VideoType>((state: RootState) => state.reviewTool.mainPaneVideoType)
    const currentlyRecordingClip = useAppSelector<string>((state: RootState) => state.reviewTool.currentlyRecordingClip)
    const paused = useAppSelector<boolean>((state: RootState) => state.reviewTool.paused)
    const [confirmDelete, setConfirmDelete] = useState(false);

    const segments = savedSegments.concat(recordedSegments)
    let standardSegments = segments.filter(s=>isNormalSegment(s.label))

    const savedSeconds = savedSegments.map( s => s.duration ? s.duration : 0).reduce( (acc, cur) => acc + cur, 0)


    useEffect(() => {
        setConfirmDelete(false)
    }, [paused])

    const playList: VideoUpdate[] = [
        (recordedIntro.uri === "" ? [] : [{
            label: recordedIntro.label,
            uri: {mp4Src: recordedIntro.uri, hlsSrc: ""},
            type: VideoType.recordedIntro,
            duration: recordedIntro.duration
        } as VideoUpdate]),
        segments.filter(rs => isNormalSegment(rs.label)).map((rs) => {
            return {
                label: rs.label,
                uri: {mp4Src: rs.uri, hlsSrc: ""},
                type: VideoType.recordedClip,
                duration: rs.duration
            }
        }),
        (recordedSummary.uri === "" ? [] : [{
            label: recordedSummary.label,
            uri: {mp4Src: recordedSummary.uri, hlsSrc: ""},
            type: VideoType.recordedSummary,
            duration: recordedIntro.duration
        }as VideoUpdate])
    ].flat()


    const SidePanelIntroButton = (
        <Button
            variant="outlined"
            className={(isIntroSegment(currentlyRecordingClip) || recordedIntro.uri !== "" ? "" : "empty") + (mainPaneVideoType === VideoType.recordedIntro ? " loaded-left" : "")}
            onClick={() => {
                dispatch(setMainPaneVideoDuration(recordedIntro.duration))
                dispatch(updateMainPaneVideo({
                    label: recordedIntro.label,
                    uri: {mp4Src: recordedIntro.uri, hlsSrc: ""},
                    type: recordedIntro.uri === "" ? VideoType.intro : VideoType.recordedIntro
                } as VideoUpdate))
            }}
        >
            {isIntroSegment(currentlyRecordingClip) ?
                <FiberManualRecordIcon style={{color: 'red'}}/>
                :
                recordedIntro.uri === "" ? <AddIcon/> : <PlayArrowIcon/>
            } { recordedIntro.uploadStatus === "Saved" ? (recordedIntro.duration ? formatSecondsToISO(recordedIntro.duration): "Saved") + " Intro" : "Intro"}{recordedIntro.uploadStatus === "" || recordedIntro.uploadStatus === "Saved" ? "" : " (" + recordedIntro.uploadStatus + ")"}
        </Button>
    )

    const SidePanelSummaryButton = (
        <Button
            variant="outlined"
            className={isSummarySegment(currentlyRecordingClip) || recordedSummary.uri !== "" ? "" : "empty"}
            onClick={() => {
                dispatch(setMainPaneVideoDuration(recordedSummary.duration))
                dispatch(updateMainPaneVideo({
                    label: recordedSummary.label,
                    uri: {mp4Src: recordedSummary.uri, hlsSrc: ""},
                    type: recordedSummary.uri === "" ? VideoType.summary : VideoType.recordedSummary
                }))
            }}
        >
            {isSummarySegment(currentlyRecordingClip) ?
                <FiberManualRecordIcon style={{color:'red'}}/>
                :
                recordedSummary.uri === "" ? <AddIcon/> : <PlayArrowIcon/>
            } { recordedSummary.uploadStatus === "Saved" ? (recordedSummary.duration ? formatSecondsToISO(recordedSummary.duration): "Saved") + " Summary" : "Summary"}{recordedSummary.uploadStatus === "" || recordedSummary.uploadStatus === "Saved" ? "" : " (" + recordedSummary.uploadStatus + ")"}
        </Button>
    )

    const SidePanelSegmentButton = (rs:RecordedSegment, i: number) =>  (
        <Button
            style={{flex: 4}}
            variant="outlined"
            // className={rs.uri === "" ? "empty" : ""}
            onClick={() => {
                dispatch(setMainPaneVideoDuration(rs.duration))
                dispatch(updateMainPaneVideo({
                    label: rs.label,
                    uri: {mp4Src: rs.uri, hlsSrc: ""},
                    type: VideoType.recordedClip
                }))
            }}
        >
            {rs.uri === "" ?
                <FiberManualRecordIcon style={{color:'red'}}/>
                :
                <PlayArrowIcon/>
            } { rs.uploadStatus === "Saved" ? "" + labelSecondsToTime(rs.label)  : "Feedback " + (i+1).toString()} { rs.uploadStatus === "" || rs.uploadStatus === "Saved" ? "" : "(" + rs.uploadStatus + ")"}
        </Button>
    )


    const AreYouSureButton = (segment: RecordedSegment): React.ReactElement => {
        return (
            <ButtonGroup style={{width: "100%"}}>
                <Button className="recorded-clip-button loaded confirm confirm-delete" onClick={() => {
                    dispatch(removeRecordedSegment(segment))
                    if(reviewRequest){
                        dispatch(updateCoachFeedbackThunk(
                            {
                                uuid: reviewRequest.uuid,
                                payload: {
                                    videos: segments.filter(s => s.label !== segment.label).map(s => {
                                        return {
                                            key: s.key,
                                            mime_type: "video/mp4",
                                            label: s.label
                                        } as CoachFeedbackUploadClip
                                    })
                                } as CoachFeedbackUpdatePayload
                            }
                        ))
                    }
                    dispatch(updateMainPaneVideo({
                        label: "",
                        uri: submissionVideo,
                        type: VideoType.submission
                    }))
                }}>delete</Button>
                <Button className="recorded-clip-button loaded confirm confirm-cancel" onClick={() => setConfirmDelete(false)}>cancel</Button>
            </ButtonGroup>
        )
    }


    return (
        <div className="mainSidePanel">
            <div className="side-panel-fixed-top">
                <div className={"recorded-clip-button submission-video" + (mainPaneVideoUri === submissionVideo ? " loaded" : "")}>
                    <Button
                        variant="outlined"
                        className=""
                        onClick={() => {
                            dispatch(updateMainPaneVideo({
                                label: "",
                                uri: submissionVideo,
                                type: VideoType.submission
                            }))
                        }}
                    >
                        <MovieOutlinedIcon/>
                    </Button>
                </div>
            </div>
            <div className="side-panel-scrollable">
                <Divider className="side-panel-divider"/>
                <div className={"recorded-clip-button" + (mainPaneVideoType === VideoType.intro || mainPaneVideoType === VideoType.recordedIntro ? " loaded" : "")}>
                    { mainPaneVideoType === VideoType.recordedIntro ?
                        confirmDelete ?
                            AreYouSureButton(recordedIntro)
                            :
                            <ButtonGroup style={{width: "100%"}}>
                                {SidePanelIntroButton}
                                <Button className="loaded-right" style={{width: 40}} onClick={() => setConfirmDelete(true)}><DeleteIcon style={{color: "#999999"}}/></Button>
                            </ButtonGroup>
                        :
                        SidePanelIntroButton
                    }
                </div>
                <Divider className="side-panel-divider"/>
                {standardSegments.map((rs, i) => { return (
                    <div className={"recorded-clip-button" + (mainPaneVideoUri.mp4Src === rs.uri ? " loaded" : "")} key={rs.label + i}>
                        {mainPaneVideoUri.mp4Src === rs.uri ?
                            confirmDelete ?
                                AreYouSureButton(rs)
                                :
                                <ButtonGroup style={{width: "100%"}}>
                                    {SidePanelSegmentButton(rs, i)}
                                    <Button className="loaded-right" style={{width: 40}}
                                            onClick={() => setConfirmDelete(true)}><DeleteIcon
                                        style={{color: "#999999"}}/></Button>
                                </ButtonGroup>
                            :
                            <div style={{display: "flex"}} className="side-panel-button-row">
                                {SidePanelSegmentButton(rs, i)}
                                <div className="side-panel-up-down-button-group">
                                    <span className="side-panel-up-down-button"
                                          onClick={ () => {
                                              if(reviewRequest){
                                                  const after = [recordedIntro, arraySlideForward(standardSegments, i), recordedSummary].flat().filter(s => s.key !== undefined)
                                                  dispatch(updateSavedSegments(after))
                                                  dispatch(updateCoachFeedbackThunk(
                                                  {
                                                      uuid: reviewRequest.uuid,
                                                      payload: {
                                                          videos: savedSegmentToCFVideo(after)
                                                      } as CoachFeedbackUpdatePayload
                                                  } as CoachFeedbackUpdateRequest
                                                  ))
                                              }
                                          }}
                                    ><KeyboardArrowUpIcon/></span>
                                    <span className="side-panel-up-down-button"
                                          onClick={ () => {
                                              if(reviewRequest){
                                                  const after = [recordedIntro, arraySlideBack(standardSegments, i), recordedSummary].flat().filter(s => s.key !== undefined)
                                                  dispatch(updateSavedSegments(after))
                                                  dispatch(updateCoachFeedbackThunk(
                                                      {
                                                          uuid: reviewRequest.uuid,
                                                          payload: {
                                                              videos: savedSegmentToCFVideo(after)
                                                          } as CoachFeedbackUpdatePayload
                                                      } as CoachFeedbackUpdateRequest
                                                  ))
                                              }
                                          }}
                                    ><KeyboardArrowDownIcon/></span>
                                </div>
                            </div>
                        }
                    </div>
                )})}
                <div className="recorded-clip-button">
                    <Button
                        variant="outlined"
                        className="empty"
                        onClick={() => {
                            dispatch(updateMainPaneVideo({
                                label: "",
                                uri: submissionVideo,
                                type: VideoType.submission
                            }))
                        }}
                    >
                        <AddIcon/> Feedback
                    </Button>
                </div>
                <Divider className="side-panel-divider"/>
                <div className={"recorded-clip-button" + (mainPaneVideoType === VideoType.summary || mainPaneVideoType === VideoType.recordedSummary ? " loaded" : "")}>
                    { mainPaneVideoType === VideoType.recordedSummary ?
                        confirmDelete ?
                            AreYouSureButton(recordedSummary)
                            :
                            <ButtonGroup style={{width: "100%"}}>
                                {SidePanelSummaryButton}
                                <Button className="loaded-right" style={{width: 40}} onClick={() => setConfirmDelete(true)}><DeleteIcon style={{color: "#999999"}}/></Button>
                            </ButtonGroup>
                        :
                        SidePanelSummaryButton
                    }
                </div>
            </div>
            <div className="side-panel-fixed-bottom">
                <Divider className="side-panel-divider"/>
                <div className="recorded-clip-button play-all-button">
                    <Button
                        variant="outlined"
                        className={playList.length < 1 ? "empty": ""}
                        disabled={playList.length < 1}
                        onClick={()=>{
                            dispatch(setMainPaneVideoDuration(playList[0].duration))
                            dispatch(updateMainPaneVideo({
                                label: playList[0].label,
                                uri: playList[0].uri,
                                type: playList[0].type,
                                playList: playList.slice(1)
                            }))
                        }}
                    >
                        <PlayCircleIcon/>
                        {formatSecondsToISO(savedSeconds)}
                    </Button>
                </div>
            </div>

        </div>
    )
}